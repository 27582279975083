import { types } from "mobx-state-tree"

export const AddressModel = types
  .model("AddressModel")
  .props({
    id: types.identifierNumber,
    streetAddress: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    province: types.optional(types.string, ""),
    postalCode: types.optional(types.string, ""),
  })
  .actions((self) => ({}))
