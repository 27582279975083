import {
  Button,
  HStack,
  Input,
  InputGroup,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react"
import { contains } from "ramda"
import React, { useState } from "react"

export const StringArray = ({ arrayValues, setArrayValues }) => {
  const [newValue, setNewValue] = useState("")
  const addValue = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (newValue != "" && !contains(arrayValues, newValue)) {
      setArrayValues([...arrayValues, newValue])
      setNewValue("")
    }
  }
  return (
    <>
      {arrayValues.length > 0 && (
        <Stack spacing={2} isInline flexWrap="wrap" mt={2}>
          {arrayValues.map((value) => (
            <Tag mb={1} key={value} variant="subtle" size="md">
              <TagLabel>{value}</TagLabel>
              <TagCloseButton
                onClick={(e) => {
                  e.stopPropagation()
                  setArrayValues(arrayValues.filter((arVal) => arVal !== value))
                }}
                aria-label="Remove"
              />
            </Tag>
          ))}
        </Stack>
      )}
      <HStack mt={2}>
        <InputGroup>
          <Input
            placeholder="Add new"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                addValue(e)
              }
            }}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </InputGroup>
        <Button onClick={addValue}>{"Add"}</Button>
      </HStack>
    </>
  )
}
