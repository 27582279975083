import { Box, Center, Flex, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import * as R from "ramda"
import React from "react"
import { ContactCard } from "./contact-card"

export const DirectorySection = observer((props) => {
  const renderContactCards = () => {
    return R.map(
      (contact) => (
        <ContactCard
          key={`directory-section${contact.id}-${contact.name}`}
          contactName={contact.name}
          facilityName={props.facilityName}
          contactType={contact.contactType}
          number={contact.number}
          contactId={contact.id}
          showBookmark={true}
          isFavorited={contact.isFavoritedWithLocal}
        />
      ),
      props.contacts
    )
  }

  return (
    <Box>
      <Flex>
        <Center fontSize="18px" p={1} m="auto">
          <Text color="text" fontWeight="bold">
            {props.directoryName.toUpperCase()}
          </Text>
        </Center>
      </Flex>
      {renderContactCards()}
    </Box>
  )
})
