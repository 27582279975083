import { Box, FormControl, Input, Stack } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { ConnectForm } from "./index"
import { InputLabel } from "./input-label"

export const AdminContact = () => {
  const { t } = useTranslation()

  return (
    <ConnectForm>
      {({ register, getValues, errors }) => {
        return (
          <Box mt={3} backgroundColor="background" borderRadius={4}>
            <Stack spacing={5}>
              <FormControl className="admin-contact-name">
                <InputLabel>{t("physician.adminContactName")} </InputLabel>
                <Input
                  backgroundColor="white"
                  color="text"
                  fontSize="14px"
                  {...register("adminName")}
                />
              </FormControl>
            </Stack>
          </Box>
        )
      }}
    </ConnectForm>
  )
}
