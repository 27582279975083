import { Circle } from "@chakra-ui/react"
import React from "react"
import { ContactInfoIcon } from "./icons/contact-info-icon"

export const ContactButton = (props) => {
  const buttonColor = (contactType) => {
    switch (contactType) {
      case "admin":
      case "office":
      case "phone":
      case "other":
        return "accentPurple"
        break
      default:
        return "primary"
    }
  }

  return (
    <Circle
      size="40px"
      border="1px"
      color={buttonColor(props.contactType)}
      fontSize="1.5em"
    >
      <ContactInfoIcon
        icon={props.contactType}
        color={buttonColor(props.contactType)}
      />
    </Circle>
  )
}
