import { Box, Center, Flex, Text } from "@chakra-ui/react"
import React from "react"
import { Link } from "react-router-dom"
import { ContactButton } from "../../../shared/contact-button"
import { ClickableIcon } from "../../../shared/icons"
import { RemoveFromFavouritesButton } from "../../../shared/remove-from-favourites-btn"
import { SpecialtyTextOnCards } from "../../../shared/specialties"
import { PhysicianAvatar } from "../shared"

export const PhysicianCard = (props) => {
  return (
    <Flex bg="white" minH="80px">
      <Box pl={4} pr={4} pt={2} pb={2}>
        <Link to={`/physicians/${props.physicianId}`}>
          <PhysicianAvatar
            name={props.name}
            profileImage={props.profileImage}
            size={"lg"}
          />
        </Link>
      </Box>

      <Box mt="8px" flex="1">
        <Link to={`/physicians/${props.physicianId}`}>
          <Text fontSize="16px" fontWeight="700" color="text">
            {props.name}
          </Text>
          <Text fontSize="14px" color={"textMuted"}>
            {props.primaryPractice}
          </Text>

          <SpecialtyTextOnCards>
            {props.specialties?.[0]?.name}
          </SpecialtyTextOnCards>
        </Link>
      </Box>
      <Center>
        <Center pr={4}>
          {props.editView ? (
            <RemoveFromFavouritesButton onClick={props.handleRemove} />
          ) : (
            props.preferredPhone && (
              <ClickableIcon
                contactType={props.preferredPhone.phoneType}
                contactValue={props.preferredPhone.phoneNumber}
                smsPreferred={props.preferredPhone.smsPreferred}
              >
                <ContactButton contactType={props.preferredPhone.phoneType} />
              </ClickableIcon>
            )
          )}
        </Center>
      </Center>
    </Flex>
  )
}
