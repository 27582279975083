import { Icon } from "@chakra-ui/react"
import React from "react"

export const CloseIcon = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M8 7.99999L2.34315 2.34313M2.34315 13.6568L8 7.99999L2.34315 13.6568ZM8 7.99999L13.6569 2.34313L8 7.99999ZM8 7.99999L13.6569 13.6568L8 7.99999Z"
      stroke="#222222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
