import { Box, Center, Flex, Input, Square, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useMst } from "../../../../setup"
import { PhysicianAvatar } from "../shared"
import { ConnectForm } from "./index"
import { InputLabel } from "./input-label"

export const Header = ({ existingProfileUrl }) => {
  const { t } = useTranslation()
  const { userStore } = useMst()

  return (
    <ConnectForm>
      {({ register, watch, setValue, formState: { errors } }) => {
        return (
          <Box>
            <Text color="textMuted" fontSize="16px" mb="10px">
              {t("physician.signedInAs", {
                userEmail: userStore.profile.email,
              })}
            </Text>
            <Text color="textMuted" fontSize="16px" mb="10px">
              {t("physician.requiredFields")}
            </Text>
            <Flex>
              <Square size="140px" mb="10px">
                <PhysicianAvatar
                  size="2xl"
                  name={`${watch("firstName")} ${watch("lastName")}`}
                  profileImage={existingProfileUrl}
                ></PhysicianAvatar>
              </Square>
              <Center>
                <Box>
                  <InputLabel>{t("physician.profilePicture")}</InputLabel>
                  <Input
                    type="file"
                    {...register("profileImage")}
                    multiple={false}
                    border="0"
                  />
                </Box>
              </Center>
            </Flex>
          </Box>
        )
      }}
    </ConnectForm>
  )
}
