import { Box, Container } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import * as R from "ramda"
import React from "react"
import { useMst } from "../../../setup"
import { ClinicCard } from "../clinics/clinic-index"
import { ContactCard } from "../directory/contact-card"
import { PhysicianCard } from "../physicians/physician-index"
import { Header } from "./index"

export const FavouriteIndex = observer(() => {
  const {
    favoriteStore: {
      displayFavoriteClinics,
      displayFavoritePhysicians,
      displayFavoriteContacts,
      displayLocalFavoriteContacts,
      editView,
      removeFavorite,
    },
    sessionStore,
  } = useMst()

  const renderFavouriteClinics = () => {
    return R.map(
      (clinic) => (
        <Box mt={2} mb={2} key={`clinic-box-${clinic.id}`}>
          <ClinicCard
            key={`clinic-${clinic.id}`}
            clinicId={clinic.id}
            name={clinic.name}
            specialties={clinic.twoSpecialties}
            shouldTruncateSpecialties={clinic.shouldTruncateSpecialties}
            editView={editView}
            officePhone={clinic.officePhone}
            handleRemove={(e) => removeFavorite("Clinic", clinic.id)}
          />
        </Box>
      ),
      displayFavoriteClinics
    )
  }

  const renderFavoritePhysicians = () => {
    return R.map(
      (physician) => (
        <Box mt={2} mb={2} key={`physician-box-${physician.id}`}>
          <PhysicianCard
            key={`physician-${physician.id}`}
            physicianId={physician.id}
            profileImage={physician.profileImage}
            name={physician.formatTitle}
            primaryPractice={physician.primaryPractice?.name}
            specialties={physician.specialties}
            editView={editView}
            preferredPhone={physician.preferredPhone}
            handleRemove={(e) => removeFavorite("Physician", physician.id)}
          />
        </Box>
      ),
      displayFavoritePhysicians
    )
  }

  const renderFavoriteContacts = (contacts) => {
    return R.map(
      (contact) => (
        <Box mt={2} mb={2} key={`contact-box-${contact.id}`}>
          <ContactCard
            key={`contact-${contact.id}-${contact.name}`}
            contactName={contact.name}
            facilityName={contact.facilityName}
            contactType={contact.contactType}
            number={contact.number}
            contactId={contact.id}
            showBookmark={false}
            editView={editView}
          />
        </Box>
      ),
      contacts
    )
  }

  const renderLoggedInFavorites = () => {
    return (
      <>
        {!R.isEmpty(displayFavoriteClinics) && renderFavouriteClinics()}
        {!R.isEmpty(displayFavoritePhysicians) && renderFavoritePhysicians()}
        {!R.isEmpty(displayFavoriteContacts) &&
          renderFavoriteContacts(displayFavoriteContacts)}
      </>
    )
  }

  return (
    <Container bg="background" minHeight={"100vh"} p={0}>
      <Header />
      <Box h="60px"></Box>
      {sessionStore.loggedIn
        ? renderLoggedInFavorites()
        : !R.isEmpty(displayLocalFavoriteContacts) &&
          renderFavoriteContacts(displayLocalFavoriteContacts)}
    </Container>
  )
})
