import { Icon } from "@chakra-ui/react"
import React from "react"

export const MenuIcon = () => (
  <Icon viewBox="0 0 16 16" fill="none">
    <line
      x1="0.5"
      y1="2.5"
      x2="15.5"
      y2="2.5"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="0.5"
      y1="7.5"
      x2="15.5"
      y2="7.5"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="0.5"
      y1="12.5"
      x2="15.5"
      y2="12.5"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
