import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link as ChakraLink,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { useMst } from "../../../setup"
import {
  BookmarkIcon,
  ChevronLeftIcon,
  LogoIcon,
  ProtectedIcon,
} from "../../shared/icons"
import { MenuIcon } from "../../shared/icons/menu-icon"

export const NavBar = ({ favourites, signInBtn, hamburgerBtn, chevronBtn }) => {
  let history = useHistory()
  const { sessionStore } = useMst()
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const goToPreviousPath = () => {
    history.goBack()
  }

  const favouritesButton = () => {
    return (
      <Link to="/favourites">
        <Button
          leftIcon={<BookmarkIcon />}
          variant="navigation"
          size="xs"
          fontWeight="bold"
          fontSize="15px"
        >
          {t("navBar.favourites")}
        </Button>
      </Link>
    )
  }

  const hamburgerButton = () => {
    return (
      <ChakraLink onClick={onOpen} ml="8px" mr="8px">
        <MenuIcon />
      </ChakraLink>
    )
  }

  const signInButton = () => {
    return (
      <Link to="/login">
        <Button
          rightIcon={<ProtectedIcon />}
          variant={"navigation"}
          size="xs"
          fontWeight="bold"
        >
          Sign In
        </Button>
      </Link>
    )
  }

  const chevronButton = () => {
    return (
      <Button
        leftIcon={<ChevronLeftIcon />}
        variant="link"
        size="sm"
        onClick={goToPreviousPath}
      />
    )
  }

  return (
    <>
      <Flex
        bg="primary"
        p={1}
        display="flex"
        position="fixed"
        w="100%"
        zIndex="2"
      >
        <Flex flex="1" marginRight="auto" justifySelf="flex-start">
          {hamburgerBtn && hamburgerButton()}
          {signInBtn && signInButton()}
          {chevronBtn && chevronButton()}
        </Flex>

        <Flex flex="1" justifyContent="center">
          {" "}
          <Link to="/physicians">
            <LogoIcon color="white" boxSize="2.3em" />
          </Link>
        </Flex>

        <Flex flex="1" justifySelf="flex-end" marginLeft="auto">
          {" "}
          {favourites && favouritesButton()}
        </Flex>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        size="xs"
        p="24px"
      >
        <DrawerOverlay>
          <DrawerContent backgroundColor="blue">
            <DrawerBody>
              <VStack>
                <LogoIcon color="white" boxSize="4em" />

                <VStack p={4} fontSize="16px" fontWeight="400" spacing="0">
                  <Text color="white">PHC Medical</Text>
                  <Text color="white">Staff Directory</Text>
                </VStack>

                <Link to="/" onClick={onClose} style={{ outline: "none" }}>
                  <Text color={"white"} fontSize="16px" fontWeight="700">
                    {t("navBar.home")}
                  </Text>
                </Link>
                <Link to="/about" onClick={onClose} style={{ outline: "none" }}>
                  <Text color={"white"} fontSize="16px" fontWeight="700">
                    {t("navBar.about")}
                  </Text>
                </Link>
                <a
                  target="_blank"
                  href="https://phconcall.hssbc.ca/"
                  onClick={onClose}
                >
                  <Text color={"white"} fontSize="16px" fontWeight="700">
                    {t("navBar.onCallLists")}
                  </Text>
                </a>
                <a href="tel:16048069090" onClick={onClose}>
                  <Text color={"white"} fontSize="16px" fontWeight="700">
                    {t("navBar.phcSwitchboard")}
                  </Text>
                </a>

                <Divider width={150} />

                {sessionStore.loggedIn ? (
                  <>
                    <Link to="/account" onClick={onClose}>
                      <Text color={"white"} fontSize="16px" fontWeight="700">
                        {t("navBar.updateAccount")}
                      </Text>
                    </Link>

                    <Link to="/profile/edit" onClick={onClose}>
                      <Text color={"white"} fontSize="16px" fontWeight="700">
                        {t("navBar.updateProfile")}
                      </Text>
                    </Link>

                    <Divider width={150} />

                    <Button
                      fontSize="16px"
                      fontWeight="700"
                      color={"white"}
                      p={3}
                      variant="link"
                      onClick={() => {
                        sessionStore.logout()
                        history.push("/")
                      }}
                    >
                      Logout
                    </Button>
                  </>
                ) : (
                  <Link to="/login" onClick={onClose}>
                    <Button variant="outline" pr={12} pl={12} fontWeight="bold">
                      Login
                    </Button>
                  </Link>
                )}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}
