import { Box, Text } from "@chakra-ui/react"
import * as R from "ramda"
import React from "react"
import { PhysicianCard } from "../../physicians/physician-index"
import { Section } from "../../physicians/physician-show"

export const People = (props) => {
  const renderPhysicians = () => {
    return R.map(
      (physician) => (
        <Box mt={2} mb={2} mr="16px" ml="16px" key={`p-card-${physician.id}`}>
          <PhysicianCard
            physicianId={physician.id}
            profileImage={physician.profileImage}
            name={physician.formatTitle}
            primaryPractice={physician.primaryPractice?.name}
            specialties={physician.specialties}
            preferredPhone={physician.preferredPhone}
            id={physician.id}
          />
        </Box>
      ),
      props.physicians
    )
  }

  return (
    <>
      <Section divider={true}>
        <Text fontSize="24px" pl={2} mt={3} mb={3} color="text">
          People
        </Text>

        <Box ml="-16px" mr="-16px" bg={"gray.50"}>
          {renderPhysicians()}
        </Box>
      </Section>
    </>
  )
}
