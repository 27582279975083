import { Icon } from "@chakra-ui/react"
import React from "react"

export const FilterIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M5.5 12.125C5.5 11.9592 5.56585 11.8003 5.68306 11.6831C5.80027 11.5658 5.95924 11.5 6.125 11.5H9.875C10.0408 11.5 10.1997 11.5658 10.3169 11.6831C10.4342 11.8003 10.5 11.9592 10.5 12.125C10.5 12.2908 10.4342 12.4497 10.3169 12.5669C10.1997 12.6842 10.0408 12.75 9.875 12.75H6.125C5.95924 12.75 5.80027 12.6842 5.68306 12.5669C5.56585 12.4497 5.5 12.2908 5.5 12.125ZM3 8.375C3 8.20924 3.06585 8.05027 3.18306 7.93306C3.30027 7.81585 3.45924 7.75 3.625 7.75H12.375C12.5408 7.75 12.6997 7.81585 12.8169 7.93306C12.9342 8.05027 13 8.20924 13 8.375C13 8.54076 12.9342 8.69973 12.8169 8.81694C12.6997 8.93415 12.5408 9 12.375 9H3.625C3.45924 9 3.30027 8.93415 3.18306 8.81694C3.06585 8.69973 3 8.54076 3 8.375ZM0.5 4.625C0.5 4.45924 0.565848 4.30027 0.683058 4.18306C0.800269 4.06585 0.95924 4 1.125 4H14.875C15.0408 4 15.1997 4.06585 15.3169 4.18306C15.4342 4.30027 15.5 4.45924 15.5 4.625C15.5 4.79076 15.4342 4.94973 15.3169 5.06694C15.1997 5.18415 15.0408 5.25 14.875 5.25H1.125C0.95924 5.25 0.800269 5.18415 0.683058 5.06694C0.565848 4.94973 0.5 4.79076 0.5 4.625Z"
      fill="currentColor"
    />
  </Icon>
)
