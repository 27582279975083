import { flow, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment } from "../lib"

interface ResourceableParams {
  collection: {
    name: string
    apiGetIndexFnName: string
  }
  member: {
    selectedResourceName: string
    apiGetMemberFnName: string
  }
}

/*
  InfiniteScrollResourceable
  -----
  aims to encapsulate the functionality needed to fetch an index / show action for a particular resource
  and automatically support pagination functionality. The resource is meant to be wrapped in an InfiniteScroll react component

  params
    - see interface ResourceableParams

  callbacks
    __beforeMergeUpdate(resourceData) - function is called before the resourceData is merged and updated
    __afterMergeUpdate(resourceData) - function is called after resourceData is merged and updated

*/

export const createInfiniteScrollResourceable = (
  params: ResourceableParams
) => {
  return types
    .model("PhysicianStoreModel")
    .props({
      page: types.optional(types.number, 1),
      hasMorePages: types.optional(types.boolean, true),
    })
    .extend(withEnvironment())
    .actions((self) => ({
      mergeUpdate(resourceData) {
        self["__beforeMergeUpdate"] && self["__beforeMergeUpdate"](resourceData)
        const { name: collectionName } = params.collection
        const existingResource = self[collectionName].get(resourceData.id) || {}
        const newData = R.mergeDeepLeft(resourceData, existingResource)
        self[collectionName].put(newData)
        self["__afterMergeUpdate"] && self["__afterMergeUpdate"](resourceData)
      },
    }))
    .actions((self) => ({
      getResources: flow(function* () {
        const response: any = yield self.environment.api[
          params.collection.apiGetIndexFnName
        ](self.page)
        if (response.ok) {
          const { data } = response
          if (!R.isEmpty(data)) {
            // we grabbed some data from current page, map it in and increment page
            R.map((resource) => self.mergeUpdate(resource), data)
            self.page += 1
          } else {
            // no more results
            self.hasMorePages = false
          }
        } else {
          //draft an alert or something for now
        }
      }),
      setSelected: flow(function* (resourceId) {
        const { selectedResourceName, apiGetMemberFnName } = params.member
        const response: any = yield self.environment.api[apiGetMemberFnName](
          resourceId
        )
        if (response.ok) {
          const { data } = response
          // do a merge since index call has minimal data, and useEffect on show sometimes has race conditions
          self.mergeUpdate(data)
          self[selectedResourceName] = data.id //set the selected physician
          return self[selectedResourceName]
        }
      }),
      resetSelected() {
        self[params.member.selectedResourceName] = null
      },
    }))
}
