import {
  Box,
  Button,
  Center,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react"
import debounce from "lodash.debounce"
import { observer } from "mobx-react-lite"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMst } from "../setup/root"
import { DirectoryShow } from "./domains/directory"
import { LoadingSpinner } from "./shared/base/loading-spinner"
import { SearchIcon } from "./shared/icons"
import { CloseIcon } from "./shared/icons/close-icon"

export const HomeVisitor = observer(() => {
  const { t } = useTranslation()
  const { contactStore } = useMst()
  const [searchTerm, setSearchTerm] = useState("")

  const directoryDebouncedSearch = useRef(
    debounce((term) => {
      contactStore.search(term)
    }, 500)
  ).current

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
    handleSearch({ searchTerm: e.target.value }) //use options as the reliable way to evaluate the search term as setState will not have called and we do not use a callback method
  }

  const handleSearch = (options = {}) => {
    directoryDebouncedSearch(options["searchTerm"] || searchTerm)
  }

  const clearSearch = () => {
    setSearchTerm("")
    contactStore.clearSearch()
    //set all the loading spinners to false
  }

  return (
    <Box>
      <Box position="fixed" bgColor="white" w="100%" zIndex="1">
        <InputGroup>
          <InputLeftElement
            height={65}
            children={<SearchIcon color="primary" />}
          />
          <Input
            height={65}
            fontSize={18}
            placeholder="Search"
            border="0px"
            value={searchTerm}
            onChange={handleInputChange}
          ></Input>

          <Button
            fontWeight="bold"
            color={"primary"}
            fontSize={15}
            height={65}
            variant="ghost"
            size="sm"
            pr={5}
            onClick={clearSearch}
          >
            <CloseIcon />
          </Button>
        </InputGroup>
        <Divider />
      </Box>

      <Center>
        <LoadingSpinner
          visible={contactStore.searchLoading}
          key={"directory-spinner"}
        />
        <DirectoryShow
          clearSearch={clearSearch}
          displayAll={searchTerm == ""}
        />
      </Center>
    </Box>
  )
})
