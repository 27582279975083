import { Container } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useMst } from "../../../../setup"
import { Header } from "./header"
import { Location } from "./location"
import { People } from "./people"

export const ClinicShow = observer((props) => {
  const { clinicStore } = useMst()
  const { selectedClinic: c } = clinicStore

  // see pattern from physician-show
  useEffect(() => {
    const { setSelected, resetSelected } = clinicStore
    setSelected(props.match.params.clinicId)

    return () => {
      resetSelected()
    }
  }, [])

  return (
    c && (
      <>
        <Container>
          <Header
            specialties={c.specialties}
            name={c.name}
            clinicId={c.id}
            isFavorited={c.isFavorited}
          />

          <Location
            phoneNumbers={c.phoneNumbers}
            streetAddress={c.address?.streetAddress}
            city={c.address?.city}
            province={c.address?.province}
            postalCode={c.address?.postalCode}
            address={c.address}
          />

          <People physicians={c.physicians} />
        </Container>
      </>
    )
  )
})
