import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment } from "../lib/with-environment"
import { FormOptionsModel } from "../models/form-options"

export const FormOptionsStoreModel = types
  .model("FormOptionsStoreModel")
  .props({
    formOptions: types.maybeNull(FormOptionsModel),
  })
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    getFormOptions: flow(function* () {
      const response: any = yield self.environment.api.getFormOptions()
      if (response.ok) {
        self.formOptions = response.data
      } else {
        //draft an alert or something for now unable to retrieve response
      }
    }),
  }))

export interface IFormOptionsStore
  extends Instance<typeof FormOptionsStoreModel> {}
