import { Box } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { getEnv } from "mobx-state-tree"
import React from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import { Home } from "."
import { rootStore, useMst } from "../setup/root"
import { AboutScreen } from "./about"
import { LoginForm } from "./domains/authentication/login-form"
import { ClinicShow } from "./domains/clinics/clinic-show/clinic-show"
import { FavouriteIndex } from "./domains/favourites/favourites-index"
import { NavBar } from "./domains/navigation/nav-bar"
import { TermsAndConditions } from "./domains/onboarding/TermsAndCondtions"
import { UpdateAccount } from "./domains/physicians/account/update-account"
import { PhysicianEditScreen } from "./domains/physicians/physician-edit/physician-edit-screen"
import { PhysicianShowScreen } from "./domains/physicians/physician-show/physician-show-screen"
import { HomeVisitor } from "./home-visitor"
import { LoadingSpinner } from "./shared/base/loading-spinner"

export const Navigation = observer((props) => {
  const { sessionStore, userStore, initialLoadInProgress } = useMst()

  const RouteMapping = () => {
    if (initialLoadInProgress) {
      return <LoadingSpinner visible={true} />
    } else {
      if (!sessionStore.loggedIn) {
        return (
          <>
            <NavBar hamburgerBtn={true} signInBtn={true} favourites={true} />
            <Box pt="35px"></Box>
            <Switch>
              <Route exact path="/" component={HomeVisitor} />
              <Route exact path="/login" component={LoginForm} />
              <Route exact path="/about" component={AboutScreen} />
              <Route exact path="/favourites" component={FavouriteIndex} />
              {
                // Redirect all 404's to home
              }
              <Redirect to="/" />
            </Switch>
          </>
        )
      } else if (
        userStore.profile &&
        userStore.profile.aasmState == "invited"
      ) {
        return <TermsAndConditions />
      } else if (
        userStore.profile &&
        userStore.profile.aasmState == "terms_accepted"
      ) {
        return <PhysicianEditScreen />
      } else if (
        userStore.profile &&
        userStore.profile.aasmState == "profile_confirmed"
      ) {
        return (
          <>
            <Switch>
              <Route
                exact
                path={["/", "/clinics", "/physicians", "/directory"]}
              >
                <NavBar hamburgerBtn={true} favourites={true} />
              </Route>
              <NavBar chevronBtn={true} />
            </Switch>
            <Box pt="35px"></Box>
            <Switch>
              {/* <Route exact path="/" component={Home} /> */}
              <Route exact path="/physicians" component={Home} />
              <Route exact path="/clinics" component={Home} />
              <Route exact path="/directory" component={Home} />

              <Route path="/clinics/:clinicId" exact component={ClinicShow} />

              <Route
                path="/physicians/:physicianId"
                exact
                component={PhysicianShowScreen}
              />
              <Route path="/favourites" exact component={FavouriteIndex} />
              <Route path="/about" exact component={AboutScreen} />
              <Route
                path="/profile/edit"
                exact
                component={PhysicianEditScreen}
              />
              <Route path="/account" exact component={UpdateAccount} />
              {
                // Redirect all 404's to home
              }
              <Redirect to="/physicians" />
            </Switch>
          </>
        )
      } else {
        return <LoadingSpinner visible={true} />
      }
    }
  }

  return (
    <Router history={getEnv(rootStore).routerHistory}>{RouteMapping()}</Router>
  )
})
