import { CUIAutoComplete } from "chakra-ui-autocomplete"
import * as R from "ramda"
import React from "react"

export interface Item {
  label: string
  value: string
}

const mapToAutoCompleteFormat = (list, attributeForLabel = "name") =>
  R.map((item) => {
    return { value: item.id, label: item[attributeForLabel] }
  }, list)

const mapSelectedItemsAndExcludeDestroyItems = (
  selectedItemsList,
  passibleValues,
  attrributeForValue
) => {
  return R.map(
    (physicianSpecialty) => {
      return {
        value: physicianSpecialty[attrributeForValue],
        label:
          (passibleValues.find(
            (s) => s.id == physicianSpecialty[attrributeForValue]
          ) || {})["name"] || "",
      }
    },
    (selectedItemsList || []).filter((spA) => spA._destroy != "1")
  )
}

//match what rails requires, if an item was removed set the _destroy = 1
//if an item had no id, it was never in the db, just remove it from list
const mergeResultsForRails = (
  initialValues,
  updatedValues,
  attributeForValue,
  additionalDefaultValues = {}
) => {
  const itemsToSelect = initialValues
    .filter(
      (item) =>
        updatedValues.find((uv) => uv.value == item[`${attributeForValue}`]) !==
        undefined
    )
    .map((item) => ({
      id: item.id,
      [`${attributeForValue}`]: item[`${attributeForValue}`],
      _destroy: null,
      ...additionalDefaultValues,
    }))
  const itemsToAdd = updatedValues
    .filter(
      (uv) =>
        initialValues.find(
          (item) => item[`${attributeForValue}`] == uv.value
        ) == undefined
    )
    .map((uv) => ({
      id: null,
      [`${attributeForValue}`]: uv.value,
      _destroy: null,
      ...additionalDefaultValues,
    }))
  const itemsToDestroy = initialValues
    .filter(
      (item) =>
        updatedValues.find((uv) => uv.value == item[`${attributeForValue}`]) ==
        undefined
    )
    .map((item) => ({
      id: item.id,
      [`${attributeForValue}`]: item[`${attributeForValue}`],
      _destroy: "1",
      ...additionalDefaultValues,
    }))
  return itemsToSelect.concat(itemsToAdd).concat(itemsToDestroy)
}

export const Autocomplete = ({
  selectedItems,
  setSelectedItems,
  attributeForValue, // what rails id we are setting like 'specialtyId'
  possibleValues,
  attributeForLabel, // from the selected items what label shows the value 'name' in specialties
  label,
  placeholder,
  additionalDefaultValues = {},
}) => {
  const handleCreateItem = (item: Item) => {
    alert(
      "This is controlled by the administrator.  Please contact them to update option."
    )
  }

  const handleSelectedItemsChange = (updatedSelectedItems?: Item[]) => {
    if (selectedItems) {
      //match what rails requires, if an item was removed set the _destroy = 1
      //if an item had no id, it was never in the db, just remove it from list

      setSelectedItems(
        mergeResultsForRails(
          selectedItems,
          updatedSelectedItems,
          attributeForValue,
          additionalDefaultValues
        )
      ) //send data back to update the state
    } else {
      setSelectedItems([])
    }
  }

  const parsedPossibleValues = mapToAutoCompleteFormat(
    possibleValues,
    attributeForLabel
  )

  return (
    <CUIAutoComplete
      label={label}
      placeholder={placeholder}
      onCreateItem={handleCreateItem}
      items={parsedPossibleValues}
      selectedItems={mapSelectedItemsAndExcludeDestroyItems(
        selectedItems,
        possibleValues,
        attributeForValue
      )}
      onSelectedItemsChange={(changes) =>
        handleSelectedItemsChange(changes.selectedItems)
      }
    />
  )
}
