import { flow, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { t } from "../i18n/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { AUTH_STORE_KEY, localCache } from "../services/api/local-cache"

export const SessionStoreModel = types
  .model("SessionStoreModel")
  .props({
    loggedIn: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false),
  })
  .extend(withEnvironment())
  .extend(withRootStore())
  .views((self) => ({}))
  .actions((self) => ({
    resetAuth: flow(function* () {
      self.loggedIn = false
      localCache.setItem(AUTH_STORE_KEY, null)
      self.environment.api.setJwt(null)
    }),
  }))
  .actions((self) => ({
    validateToken: flow(function* () {
      const bearerToken = yield localCache.getAccessTokenFromLocalStorage()
      if (bearerToken) {
        self.environment.api.setJwt(bearerToken)
        const response: any = yield self.environment.api.validateToken() // now try to validate this with the server
        if (response.ok) {
          // if server gave good response, auto login
          self.loggedIn = true
        } else {
          // invalid token / jwt was revoked
          self.resetAuth()
        }
      }
    }),
    login: flow(function* (email, password) {
      self.loading = true
      const response: any = yield self.environment.api.login(email, password)
      if (response.ok) {
        const { physicianStore, userStore, favoriteStore } = self.rootStore
        self.loading = false
        const bearerJwt = response.headers.authorization
        self.loggedIn = true
        localCache.setItem(AUTH_STORE_KEY, bearerJwt)
        self.environment.api.setJwt(bearerJwt)
        self.rootStore.loggedInStartup()
        return true
      } else {
        self.loading = false
        return false
        // uiStore flashmodal and ApiError monitor will take care of this
      }
    }),
    resetPassword: flow(function* (email) {
      if (R.isEmpty(email)) {
        self.rootStore.uiStore.flashMessage.show(
          "error",
          t("physician.passwordResetError.title"),
          t("physician.passwordResetError.message")
        )
      } else {
        yield self.environment.api.resetPassword(email)
        // errors handled by monitors
      }
    }),
    logout: flow(function* () {
      const response: any = yield self.environment.api.logout()
      if (response.ok) {
        self.resetAuth()
      }
    }),
  }))

export interface ISessionStore extends Instance<typeof SessionStoreModel> {}
