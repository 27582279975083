import { values } from "mobx"
import { flow, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { t } from "../i18n/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { FavoriteModel } from "../models/favorite"

const favoriteMapToResourceArray = (favorites, resourceCollection) => {
  return R.map((favorite) => {
    return resourceCollection.get(favorite.favoritableId)
  }, values(favorites))
}

export const FavoriteStoreModel = types
  .model("FavoriteStoreModel")
  .props({
    favoriteClinics: types.map(FavoriteModel),
    favoritePhysicians: types.map(FavoriteModel),
    favoriteContacts: types.map(FavoriteModel),
    localFavoriteContacts: types.map(FavoriteModel),
    editView: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get displayFavoriteClinics() {
      return favoriteMapToResourceArray(
        self.favoriteClinics,
        self.rootStore.clinicStore.clinics
      )
    },
    get displayFavoritePhysicians() {
      return favoriteMapToResourceArray(
        self.favoritePhysicians,
        self.rootStore.physicianStore.physicians
      )
    },
    get displayFavoriteContacts() {
      return favoriteMapToResourceArray(
        self.favoriteContacts,
        self.rootStore.contactStore.contacts
      )
    },
    get displayLocalFavoriteContacts() {
      return favoriteMapToResourceArray(
        self.localFavoriteContacts,
        self.rootStore.contactStore.contacts
      )
    },
    get useLocal() {
      return !self.rootStore.sessionStore.loggedIn
    },
  }))
  .actions((self) => ({
    getFavorites: flow(function* () {
      const response: any = yield self.environment.api.getFavorites()
      const { clinicStore, physicianStore, contactStore } = self.rootStore
      if (response.ok) {
        R.forEach((favorite) => {
          self.favoriteClinics.put(favorite)
          clinicStore.mergeUpdate(favorite.favoritable)
        }, response.data.favoriteClinics)
        R.forEach((favorite) => {
          self.favoritePhysicians.put(favorite)
          physicianStore.mergeUpdate(favorite.favoritable)
        }, response.data.favoritePhysicians)
        R.forEach((favorite) => {
          self.favoriteContacts.put(favorite)
          contactStore.updateContactData(favorite.favoritable)
        }, response.data.favoriteContacts)
      } else {
        //draft an alert or something for now
      }
    }),
  }))
  .actions((self) => ({
    createFavorite: flow(function* (favoritableType, favoritableId) {
      //check there isnt an existing favorite
      const response: any = yield self.environment.api.createFavorite(
        favoritableType,
        favoritableId,
        self.useLocal
      )
      if (response.ok) {
        const { data } = response.data
        switch (favoritableType) {
          case "Contact":
            // if local, there is no API driven success message, so show our own
            if (self.useLocal) {
              self.localFavoriteContacts.put(data)
              self.rootStore.uiStore.flashMessage.show(
                "info",
                t("favorites.successFlash.title"),
                t("favorites.successFlash.message")
              )
            } else {
              self.favoriteContacts.put(data)
            }
            break
          case "Physician":
            self.favoritePhysicians.put(data)
            break
          case "Clinic":
            self.favoriteClinics.put(data)
            break
          default:
            alert("Not valid")
        }
      }
    }),
  }))
  .actions((self) => ({
    toggleFavoritesView: () => {
      self.editView = !self.editView
    },
  }))
  .actions((self) => ({
    removeFavorite: flow(function* (favoritableType, favoritableId) {
      const response: any = yield self.environment.api.removeFavorite(
        favoritableType,
        favoritableId,
        self.useLocal
      )
      if (response.ok) {
        const { id } = response.data
        switch (favoritableType) {
          case "Contact":
            if (self.useLocal) {
              self.localFavoriteContacts.delete(id)
            } else {
              self.favoriteContacts.delete(id)
            }
            break
          case "Physician":
            self.favoritePhysicians.delete(id)
            break
          case "Clinic":
            self.favoriteClinics.delete(id)
            break
          default:
            alert("Not valid")
        }
        return true
      } else {
        return false
      }
    }),
  }))
  .actions((self) => ({
    load: flow(function* () {
      yield self.getFavorites()
      // do other setup here?
    }),
  }))

export interface IFavoriteStore extends Instance<typeof FavoriteStoreModel> {}
