import { Box, Center, Flex, FlexProps, Spacer, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React from "react"
import { ClickableIcon } from "../../../shared/icons"
import { ContactInfoIcon } from "../../../shared/icons/contact-info-icon"

export interface IContactItemProps {
  label: string
  emailOrNumber: string
  extension?: string
  icon?: string
  containerProps?: FlexProps
  smsPreferred?: boolean
}

const ContactLabel = styled(Text)``

ContactLabel.defaultProps = {
  fontSize: "14px",
  color: "secondary",
  letterSpacing: "1px",
  fontWeight: "700",
  textTransform: "uppercase",
}

export const ContactItem = (props: IContactItemProps) => {
  const extension = () => {
    return props.extension && `Ext# ${props.extension}`
  }

  return (
    <Flex bg="background" borderRadius={5} minH="50px" mb="1px">
      <Box w="25%" p="16px">
        <ContactLabel>{props.label}</ContactLabel>
      </Box>

      <Box w="70%" fontSize="16px" fontWeight="700" px="8px" py="16px">
        <ClickableIcon
          contactType={props.icon}
          contactValue={props.emailOrNumber}
          smsPreferred={props.smsPreferred}
        >
          <Text color="primary">
            {props.emailOrNumber} {extension()}
          </Text>
        </ClickableIcon>
      </Box>

      <Spacer />

      <Center fontSize="16px" p="16px">
        {<ContactInfoIcon icon={props.icon} color="primary" />}
      </Center>
    </Flex>
  )
}
