import { FormControl, Textarea, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Section } from "../physician-show"
import { ConnectForm } from "./index"
import { InputLabel } from "./input-label"

export const PersonalSection = () => {
  const { t } = useTranslation()

  return (
    <ConnectForm>
      {({ register, formState: { errors } }) => {
        return (
          <>
            <Section divider={true}>
              <FormControl className="about-me" mb="16px">
                <InputLabel>{t("physician.aboutMe")}</InputLabel>
                <Text color="textMuted" fontSize="12px">
                  {t("physicianEdit.form.aboutMeHint")}
                </Text>
                <Textarea
                  resize="none"
                  borderRadius={0}
                  fontSize="14px"
                  color="text"
                  {...register("aboutMe")}
                />
              </FormControl>

              <FormControl className="clinical-areas-interest" mb="16px">
                <InputLabel>{t("physician.clinicalAreasInterest")}</InputLabel>
                <Textarea
                  resize="none"
                  borderRadius={0}
                  fontSize="14px"
                  color="text"
                  {...register("clinicalAreasOfInterest")}
                />
              </FormControl>

              <FormControl className="research-areas" mb="16px">
                <InputLabel>{t("physician.researchAreas")}</InputLabel>
                <Textarea
                  resize="none"
                  borderRadius={0}
                  fontSize="14px"
                  color="text"
                  {...register("researchAreas")}
                />
              </FormControl>

              <FormControl className="personal-areas-interest" mb="16px">
                <InputLabel>{t("physician.personalAreasInterest")}</InputLabel>
                <Textarea
                  resize="none"
                  fontSize="14px"
                  color="text"
                  borderRadius={0}
                  {...register("personalAreasOfInterest")}
                />
              </FormControl>
            </Section>
          </>
        )
      }}
    </ConnectForm>
  )
}
