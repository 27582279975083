import { Box, FormControl } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { StringArray } from "../../../shared/string-array"
import { InputLabel } from "./input-label"

export const DepartmentRoles = ({ roles, setRoles }) => {
  const { t } = useTranslation()

  return (
    <Box mb="16px">
      <FormControl className="department-roles">
        <InputLabel>{t("physician.departmentRolesLabel")}</InputLabel>
        <StringArray arrayValues={roles} setArrayValues={setRoles} />
      </FormControl>
    </Box>
  )
}
