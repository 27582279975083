import { Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React from "react"
import { useTranslation } from "react-i18next"
import { mapIndexed } from "../../../../utils"

const TdLabel = styled(Td)``

TdLabel.defaultProps = {
  fontSize: "18px",
  fontWeight: "700",
  color: "primary",
  minW: "170px",
  letterSpacing: "1px",
  px: "0px",
}

const TdValue = styled(Td)``
TdValue.defaultProps = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#222222",
  px: "0px",
}

export const InfoTable = (props) => {
  const { t } = useTranslation()

  const renderDepartmentRoles = () => {
    return mapIndexed(
      (role, index) => <Text key={`role-${index}`}>{role}</Text>,
      props.departmentRoles
    )
  }

  return (
    <Table variant="unstyled">
      <Tbody verticalAlign="top">
        {props.location && (
          <Tr>
            <TdLabel>{t("physician.location")}</TdLabel>
            <TdValue>{props.location}</TdValue>
          </Tr>
        )}
        <Tr>
          <TdLabel>{t("physician.dept")}</TdLabel>
          <TdValue>{props.department}</TdValue>
        </Tr>
        {props.division && (
          <Tr>
            <TdLabel>{t("physician.division")}</TdLabel>
            <TdValue>{props.division}</TdValue>
          </Tr>
        )}

        {props.departmentRoles.length > 0 && (
          <Tr>
            <TdLabel>{t("physician.departmentRoles")}</TdLabel>
            <TdValue px="0px"> {renderDepartmentRoles()}</TdValue>
          </Tr>
        )}

        {props.caseTypes && (
          <Tr>
            <TdLabel>{t("physician.caseTypes")}</TdLabel>
            <TdValue>{props.caseTypes}</TdValue>
          </Tr>
        )}

        {props.mspid && (
          <Tr>
            <TdLabel>{t("physician.mspid")}</TdLabel>
            <TdValue>{props.mspid}</TdValue>
          </Tr>
        )}
      </Tbody>
    </Table>
  )
}
