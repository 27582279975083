import {
  CONNECTION_ERROR,
  NETWORK_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  UNKNOWN_ERROR,
} from "apisauce"

export const API_ERROR_TYPES = {
  [TIMEOUT_ERROR]: {
    message:
      "We're sorry, the server did not respond in time.  Please try again.",
    type: "danger",
  },
  [UNKNOWN_ERROR]: {
    message:
      "We're sorry, something unexpected happened. Please check your network connection.",
    type: "danger",
  },
  [SERVER_ERROR]: {
    message: "We're sorry, something unexpected happened. Please try again.",
    type: "danger",
  },
  [NETWORK_ERROR]: {
    message:
      "We couldn't connect to the internet! Please check your connection.",
    type: "danger",
  },
  [CONNECTION_ERROR]: {
    message:
      "We're sorry! We can't reach anipet's servers right now. Please try again.",
    type: "danger",
  },
}
