import { Box, Center, Flex, Text } from "@chakra-ui/react"
import React from "react"
import { Link } from "react-router-dom"
import { mapIndexed } from "../../../../utils"
import { ContactButton } from "../../../shared/contact-button"
import { ClickableIcon } from "../../../shared/icons"
import { RemoveFromFavouritesButton } from "../../../shared/remove-from-favourites-btn"
import { SpecialtyTextOnCards } from "../../../shared/specialties"

export const ClinicCard = (props) => {
  const renderSpecialties = () => {
    return mapIndexed(
      (specialty, index) => (
        <SpecialtyTextOnCards key={`c-specialty-${specialty.id}`}>
          {specialty.name}
          {index === 1 && props.shouldTruncateSpecialties && " ..."}
        </SpecialtyTextOnCards>
      ),
      props.specialties
    )
  }

  return (
    <Flex bg={"white"} minH="80px">
      <Box pl={4} pr={4} pt={2} pb={2} flex="1">
        <Link to={`/clinics/${props.clinicId}`}>
          <Text fontSize="16px" fontWeight="700" color="text">
            {props.name}
          </Text>

          {renderSpecialties()}
        </Link>
      </Box>
      <Center pr={4}>
        {props.editView ? (
          <RemoveFromFavouritesButton onClick={props.handleRemove} />
        ) : (
          props.officePhone && (
            <ClickableIcon
              contactType={props.officePhone.phoneType}
              contactValue={props.officePhone.number}
            >
              <ContactButton contactType={props.officePhone.phoneType} />
            </ClickableIcon>
          )
        )}
      </Center>
    </Flex>
  )
}
