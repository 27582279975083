import { types } from "mobx-state-tree"
import { AddressModel } from "./address"
import { PhoneModel } from "./phone"

export const LocationModel = types
  .model("LocationModel")
  .props({
    id: types.identifierNumber,
    name: types.optional(types.string, ""),
    address: types.maybeNull(AddressModel),
    phoneNumbers: types.array(PhoneModel),
    type: types.string,
    primaryPractice: types.maybeNull(types.boolean),
    details: types.maybeNull(types.string),
  })
  .views((self) => ({
    get label() {
      return self.type
    },
  }))
  .actions((self) => ({}))
