import { ChakraProvider } from "@chakra-ui/react"
import "@fontsource/roboto"
import "@fontsource/roboto/500.css"
import React from "react"
import ReactDOM from "react-dom"
import { Navigation } from "../components"
import { FlashMessage } from "../components/shared/base/flash-message"
import { LoadingSpinnerOverlay } from "../components/shared/base/loading-spinner-overlay"
import "../i18n/i18n"
import { Provider, rootStore } from "../setup"
import { theme } from "../styles/theme"

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Provider value={rootStore}>
      <ChakraProvider theme={theme}>
        <base href="/" />
        <FlashMessage />
        <LoadingSpinnerOverlay />
        <Navigation />
      </ChakraProvider>
    </Provider>,
    document.body.appendChild(document.getElementById("root"))
  )
})
