import { flow, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { withRootStore } from "../lib"
import { withEnvironment } from "../lib/with-environment"
import { ProfileModel } from "../models/profile"

const IMAGE_PROCESSING_DELAY_MS = 1500
const MAX_PROFILE_IMAGE_POLL_STRIKES = 5

export const UserStoreModel = types
  .model("UserStoreModel")
  .props({
    profile: types.maybeNull(ProfileModel),
    loading: types.optional(types.boolean, false),
  })
  .volatile((self) => ({
    profileImageLongPollStrikes: 0,
  }))
  .extend(withEnvironment())
  .extend(withRootStore())
  .views((self) => ({}))
  .actions((self) => ({
    getProfile: flow(function* () {
      const response: any = yield self.environment.api.getProfile()
      if (response.ok) {
        const {
          data: { profile },
        } = response
        self.profile = profile
      } else {
        //draft an alert or something for now unable to retrieve response
      }
    }),
    setProfile(profile) {
      self.profile = profile
    },
    incrementProfileImageLongPollStrikes() {
      self.profileImageLongPollStrikes += 1
    },
    resetProfileImageLongPollStrikes() {
      self.profileImageLongPollStrikes = 0
    },
  }))
  .actions((self) => ({
    pollForProcessedProfileImage() {
      setTimeout(() => {
        self.getProfile().then(() => {
          if (
            R.isNil(self.profile.profileImage) &&
            self.profileImageLongPollStrikes < MAX_PROFILE_IMAGE_POLL_STRIKES
          ) {
            self.incrementProfileImageLongPollStrikes()
            self.pollForProcessedProfileImage()
          } else {
            self.resetProfileImageLongPollStrikes()
          }
        })
      }, IMAGE_PROCESSING_DELAY_MS)
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      self.getProfile()
    }),
    pollForProcessedProfileImageIfNecessary(values) {
      // if we have tried to update profileImage, keep polling thumbnail asynchronously until its processed
      if (R.has("profileImage", values)) {
        self.pollForProcessedProfileImage()
      }
    },
  }))
  .actions((self) => ({
    acceptTermsAndConditions: flow(function* (id) {
      const response: any = yield self.environment.api.acceptTermsAndConditions(
        id
      )
      if (response.ok) {
        const { data } = response
        const {
          physicianStore: { mergeUpdate },
        } = self.rootStore
        self.profile = response.data
        mergeUpdate(response.data)
        return true
      } else {
        return false
        //make an alert or something
      }
    }),
    updateProfile: flow(function* (id, values) {
      self.loading = true
      const response: any = yield self.environment.api.updatePhysician(
        id,
        values
      )
      if (response.ok) {
        const { data } = response.data
        const {
          physicianStore: { mergeUpdate },
        } = self.rootStore
        self.profile = data
        mergeUpdate(data)
        self.loading = false
        self.pollForProcessedProfileImageIfNecessary(values)
        return true
      } else {
        self.loading = false
        return false
      }
    }),
  }))

export interface IUserStore extends Instance<typeof UserStoreModel> {}
