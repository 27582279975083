import { flow, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment } from "../lib"

/* 
  Searchable composition MST node
  Meant to connect to backend and implement 'search' functions

  params
    model - model type (eg. PhysicianModel)
    mapCollectionName - name of the mapCollection in the store you are mixing into (eg. 'physicians')
    apiSearchFnName - will look into the API instance in the environment and execute the function with given name (eg. 'searchPhysicians')

  callbacks
    __afterSuccessfulSearch(modelInstance) - if this function is implemented, it will execute passing in each instance of the model that returned from API
*/
export const createSearchable = (model, mapCollectionName, apiSearchFnName) => {
  return types
    .model({
      searchResults: types.array(types.reference(model)),
      searchLoading: types.optional(types.boolean, false),
    })
    .extend(withEnvironment())
    .views((self) => ({
      get hasSearchResults() {
        if (self.searchLoading) {
          return true
        } else {
          return self.searchResults.length != 0 // && !self.searchLoading
        }
      },
    }))
    .actions((self) => ({
      search: flow(function* (...searchParams) {
        self.searchLoading = true
        const searchFn = self.environment.api[apiSearchFnName]
        const response: any = yield searchFn(...searchParams)
        if (response.ok) {
          //update the data of any objects from search
          R.map((searchable) => {
            self[mapCollectionName].put(searchable)
            // callback __afterSuccessfulSearch
            self["__afterSuccessfulSearch"] &&
              self["__afterSuccessfulSearch"](searchable)
          }, response.data)
          self.searchResults = R.pluck("id", response.data)
          self.searchLoading = false
        } else {
          self.searchLoading = false
        }
      }),
      clearSearch: flow(function* () {
        self.searchLoading = false
      }),
    }))
}
