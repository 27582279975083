import { Box, Center, Container, Spinner } from "@chakra-ui/react"
import { values } from "mobx"
import { observer } from "mobx-react-lite"
import * as R from "ramda"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useMst } from "../../../../setup"
import { NoResultsMessage } from "../../../shared/base/no-results-message"
import { ClinicCard } from "./clinic-card"

export const ClinicIndexScreen = observer(({ displayAll, clearSearch }) => {
  const { clinicStore } = useMst()
  const { t } = useTranslation()
  const {
    clinics,
    getResources: getClinics,
    hasMorePages,
    hasSearchResults,
    searchResults,
  } = clinicStore
  const displayClinics = values(clinics)

  useEffect(() => {
    clearSearch()
    // return () => {
    //   clearSearch()
    // }
  }, [])

  const renderClinics = (clinics) => {
    return R.map(
      (clinic) => (
        <Box mt={2} mb={2} key={`c-card-${clinic.id}`}>
          <ClinicCard
            clinicId={clinic.id}
            name={clinic.name}
            specialties={clinic.twoSpecialties}
            shouldTruncateSpecialties={clinic.shouldTruncateSpecialties}
            officePhone={clinic.officePhone}
          />
        </Box>
      ),
      clinics
    )
  }

  const renderInifiniteScrollClinics = () => {
    return (
      <InfiniteScroll
        dataLength={displayClinics.length}
        next={getClinics}
        hasMore={hasMorePages}
        loader={
          <Center>
            <Spinner position="fixed" size="md" />
          </Center>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>{t("home.infiniteScrollEndMessage")}</b>
          </p>
        }
      >
        {renderClinics(displayClinics)}
      </InfiniteScroll>
    )
  }

  return (
    <Container bg="background" minHeight={"100vh"} pr={0} pl={0}>
      {displayAll ? (
        renderInifiniteScrollClinics()
      ) : hasSearchResults ? (
        renderClinics(searchResults)
      ) : (
        <NoResultsMessage />
      )}
    </Container>
  )
})
