import { types } from "mobx-state-tree"

export const FavoriteModel = types
  .model("FavoriteModel")
  .props({
    id: types.identifierNumber,
    physicianId: types.maybeNull(types.number),
    favoritableType: types.optional(types.string, ""),
    favoritableId: types.maybeNull(types.number),
  })
  .actions((self) => ({}))
