import { Icon } from "@chakra-ui/react"
import React from "react"

export const FaxIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M5.8 0.5V3.5H4.6V2.3H1V13.1H2.2V13.7C2.2 14.687 3.013 15.5 4 15.5C4.987 15.5 5.8 14.687 5.8 13.7V13.1H15.4V3.5H13V0.5H5.8ZM7 1.7H11.8V4.7H7V1.7ZM2.2 3.5H3.4V11.9H2.2V3.5ZM4.6 4.7H5.8V5.9H13V4.7H14.2V11.9H4.6V4.7ZM6.4 7.1V8.3H7.6V7.1H6.4ZM8.8 7.1V8.3H10V7.1H8.8ZM11.2 7.1V8.3H12.4V7.1H11.2ZM6.4 9.5V10.7H7.6V9.5H6.4ZM8.8 9.5V10.7H10V9.5H8.8ZM11.2 9.5V10.7H12.4V9.5H11.2ZM3.4 13.1H4.6V13.7C4.6 14.033 4.333 14.3 4 14.3C3.667 14.3 3.4 14.033 3.4 13.7V13.1Z"
    />
  </Icon>
)
