import {
  Box,
  Button,
  Checkbox,
  Container,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { CONSTANTS } from "../../../services/constants"
import { useMst } from "../../../setup"

export const TermsAndConditions = () => {
  let history = useHistory()
  const { t } = useTranslation()
  const { userStore } = useMst()
  const [checkedPolices, setCheckedPolices] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)

  const handleClick = (id) => {
    userStore.acceptTermsAndConditions(id).then((success) => {
      if (success) {
        history.push(`/profile/edit`)
      }
    })
  }

  return (
    <Container>
      <Stack spacing={10} marginTop={10}>
        <Text fontSize={24}>{t("onboarding.termsConditions")}</Text>
        <Checkbox
          isChecked={checkedPolices}
          onChange={(e) => setCheckedPolices(e.target.checked)}
        >
          <Text fontSize={14}>
            I have read PHC's{" "}
            <Link href={`${CONSTANTS.EMAIL_AND_TEXT_POLICIES_LINK}`} isExternal>
              E-mail and Texting Practices & Policies.
            </Link>
          </Text>
        </Checkbox>

        <Checkbox
          isChecked={checkedTerms}
          onChange={(e) => setCheckedTerms(e.target.checked)}
        >
          <Text fontSize={14}>
            I Agree with{" "}
            <Link href={`${CONSTANTS.TERMS_AND_CONDITIONS_LINK}`} isExternal>
              {" "}
              Terms and Conditions.
            </Link>
          </Text>
        </Checkbox>
        <Box>
          <Button
            variant="outline"
            isDisabled={!checkedPolices || !checkedTerms}
            onClick={() => handleClick(userStore.profile.id)}
          >
            {t("onboarding.submitButton")}
          </Button>
        </Box>
      </Stack>
    </Container>
  )
}
