import { Box, Center, Flex, Link, Spacer, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React from "react"
import { useMst } from "../../../setup"
import { ContactButton } from "../../shared/contact-button"
import { BookmarkIcon, ClickableIcon } from "../../shared/icons"
import { RemoveFromFavouritesButton } from "../../shared/remove-from-favourites-btn"
import { SpecialtyTextOnCards } from "../../shared/specialties"

export const ContactCard = observer((props) => {
  const { favoriteStore } = useMst()

  const handleClick = () => {
    favoriteStore.createFavorite("Contact", props.contactId)
  }

  const handleRemove = () => {
    favoriteStore.removeFavorite("Contact", props.contactId)
  }

  return (
    <>
      <Flex bg={"white"} mb="10px">
        <Box pl={4} pr={4} pt={2} pb={2}>
          <Text fontSize="16px" fontWeight="700" color="text">
            {props.contactName}
          </Text>
          <Text fontSize="14px" color="textMuted">
            {props.facilityName}
          </Text>
          <SpecialtyTextOnCards>{props.number}</SpecialtyTextOnCards>
        </Box>
        <Spacer />
        <Center>
          {props.showBookmark && !props.isFavorited && (
            <Link onClick={handleClick}>
              <BookmarkIcon color="gray.200" mr={6} boxSize="1.5em" />
            </Link>
          )}
        </Center>

        <Center pr={4}>
          {props.editView ? (
            <RemoveFromFavouritesButton onClick={handleRemove} />
          ) : (
            props.number && (
              <ClickableIcon
                contactType={props.contactType}
                contactValue={props.number}
              >
                <ContactButton contactType={props.contactType} />
              </ClickableIcon>
            )
          )}
        </Center>
      </Flex>
    </>
  )
})
