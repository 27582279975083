import * as humps from "humps"
import * as R from "ramda"

export const camelizeResponse = (data: { [key: string]: any }) => {
  return humps.camelizeKeys(data)
}

export const decamelizeRequest = (params: { [key: string]: any }) => {
  //If we need to override functionality: https://github.com/domchristie/humps#converting-object-keys
  return humps.decamelizeKeys(params)
}

export const mapIndexed = R.addIndex(R.map)

export const resetToHomeScreen = (history) => {
  history.push(`/`)
  window.scrollTo(0, 0)
}

export const acDisplay = (googleAddressComponent) => {
  if (googleAddressComponent) {
    return googleAddressComponent.long_name
  } else {
    return ""
  }
}

export const addressDataFromGooglePlacesObject = (placesObject) => {
  const { name, address_components } = placesObject
  const streetAddress = `${acDisplay(address_components[0])} ${acDisplay(
    address_components[1]
  )}`
  const city = acDisplay(address_components[3])
  const province = acDisplay(address_components[5])
  const postalCode = acDisplay(address_components[7])
  return {
    name,
    streetAddress,
    city,
    province,
    postalCode,
  }
}
