import React from "react"

export const ClickableIcon = (props) => {
  const linkType = () => {
    if (props.smsPreferred) {
      return "sms"
    } else {
      return "tel"
    }
  }

  switch (props.contactType) {
    case "office":
    case "cell":
    case "other":
    case "office":
    case "admin":
    case "phone":
      return (
        <a href={`${linkType()}:${props.contactValue}`}>{props.children}</a>
      )
      break

    case "email":
      return <a href={`mailto:${props.contactValue}`}>{props.children}</a>
      break

    default:
      return <>{props.children}</>
      break
  }
}
