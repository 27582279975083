import { Box, Container } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useMst } from "../../../../setup"
import {
  ContactItem,
  Header,
  InfoTable,
  PracticeLocationList,
  SecondaryContactInfo,
  Section,
  TextBox,
} from "./index"

export const PhysicianShowScreen = observer((props) => {
  const { t } = useTranslation()
  const { physicianStore } = useMst()
  const { selectedPhysician: p } = physicianStore

  useEffect(() => {
    const { setSelected, resetSelected } = physicianStore
    setSelected(props.match.params.physicianId)

    return () => {
      resetSelected() //on unmount set back to null
    }
  }, []) // use empty dependency here to run only once

  return (
    p && (
      <Container>
        <Box maxW="lg">
          <Header
            name={p.formatTitle}
            specialties={p.specialties}
            profileImage={p.profileImage}
            physicianId={p.id}
            isFavorited={p.isFavorited}
          />
          <InfoTable
            location={p.primaryPractice?.name}
            department={p.department?.name}
            division={p.division?.name}
            mspid={p.mspid}
            departmentRoles={p.departmentRole}
            caseTypes={p.caseType}
          />
          <Section divider={true} title={t("physician.preferredContactInfo")}>
            <Box mb="8px">
              <ContactItem
                label={p.preferredEmail?.label}
                emailOrNumber={p.preferredEmail?.email}
                extension={p.preferredEmail?.extension}
                icon={p.preferredEmail?.icon}
              />
            </Box>

            <Box>
              <ContactItem
                label={p.preferredPhone?.label}
                emailOrNumber={p.preferredPhone?.phoneNumber}
                extension={p.preferredPhone?.extension}
                icon={p.preferredPhone?.icon}
                smsPreferred={p.preferredPhone?.smsPreferred}
              />
            </Box>
          </Section>

          <Section divider={true} title={t("physician.secondaryContactInfo")}>
            <SecondaryContactInfo
              additionalContact={p.additionalContactDescription}
              adminName={p.adminName}
              secondaryContacts={p.contactsExceptAdminAndOther}
              adminContacts={p.adminContacts}
              otherContacts={p.otherContacts}
            />
          </Section>

          {(p.primaryPractice || p.secondaryPractices) && (
            <Section
              divider={true}
              title={t("physician.practiceLocationHeader")}
            >
              <PracticeLocationList
                primaryPractice={p.primaryPractice}
                secondaryPractices={p.secondaryPractices}
                secondaryClinics={p.secondaryClinics}
                secondaryFacilities={p.secondaryFacilities}
                secondaryOffices={p.secondaryOffices}
              />
            </Section>
          )}

          {p.aboutMe && (
            <Section>
              <TextBox label={t("physician.about")} description={p.aboutMe} />
            </Section>
          )}

          {p.clinicalAreasOfInterest && (
            <Section>
              <TextBox
                label={t("physician.clinicalAreasInterest")}
                description={p.clinicalAreasOfInterest}
              />
            </Section>
          )}

          {p.researchAreas && (
            <Section>
              <TextBox
                label={t("physician.researchAreas")}
                description={p.researchAreas}
              />
            </Section>
          )}

          {p.personalAreasOfInterest && (
            <Section>
              <TextBox
                label={t("physician.personalAreasInterest")}
                description={p.personalAreasOfInterest}
              />
            </Section>
          )}
        </Box>
      </Container>
    )
  )
})
