import { AlertStatus } from "@chakra-ui/react"
import { applySnapshot, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

const DEFAULT_STATE = {}

export const FlashMessageModel = types
  .model("FlashMessageModel")
  .props({
    isVisible: types.maybeNull(types.boolean),
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    status: types.optional(
      types.enumeration(["error", "success", "warning", "info"]),
      "info"
    ),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    textColor() {
      if (self.status == "info") {
        return "white"
      } else {
        return "black"
      }
    },
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, DEFAULT_STATE)
    },
  }))
  .actions((self) => ({
    show(status: AlertStatus, title: string, description: string) {
      self.status = status
      self.title = title
      self.description = description
      self.isVisible = true
      setTimeout(() => self.reset(), self.status == "error" ? 5000 : 2000)
    },
  }))

export interface IFlashMessage extends Instance<typeof FlashMessageModel> {}
