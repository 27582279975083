import { HStack, Link, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React from "react"
import { useMst } from "../../../setup"
import { CheckIcon } from "../../shared/icons"

export const Header = observer((props) => {
  const { favoriteStore } = useMst()

  return (
    <HStack
      bg="background"
      justify="space-between"
      position="fixed"
      width="inherit"
      maxWidth="inherit"
      zIndex="1"
    >
      <Text
        pl={6}
        pr={4}
        pt={4}
        pb={2}
        color="text"
        fontWeight="700"
        fontSize="24px"
      >
        Favourites
      </Text>

      {favoriteStore.editView ? (
        <Link
          mr={8}
          pr="16px"
          fontSize={14}
          fontWeight="bold"
          onClick={favoriteStore.toggleFavoritesView}
        >
          <CheckIcon />
          Done
        </Link>
      ) : (
        <Link
          fontSize={14}
          mr={8}
          pr="16px"
          fontWeight="bold"
          onClick={favoriteStore.toggleFavoritesView}
        >
          Edit
        </Link>
      )}
    </HStack>
  )
})
