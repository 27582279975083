import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react"
import * as R from "ramda"
import React from "react"
import { useTranslation } from "react-i18next"
import { EmailIcon } from "../../../shared/icons/email-icon"
import { InputLabel } from "./input-label"
import { StyledBox } from "./PhoneNumber"

export const Email = (props) => {
  const readableType = (emailType) => {
    return R.replace("_", " ", emailType)
  }

  const { t } = useTranslation()
  //if there is a field index assume its an array of items and the field name is the name of the array
  const fieldName = !R.isNil(props.fieldIndex)
    ? `${props.fieldName}[${props.fieldIndex}]`
    : props.fieldName
  const errorsAttribute = !R.isNil(props.fieldIndex)
    ? props.errors?.[props.fieldName]?.[props.fieldIndex]
    : props.errors?.[props.fieldName]
  return (
    <StyledBox mb="24px" selected={props.field.preferred}>
      <input type="hidden" {...props.register(`${fieldName}.id`)} />
      <input type="hidden" {...props.register(`${fieldName}.preferred`)} />
      <FormControl className="email">
        <Flex justifyContent={"space-between"}>
          <InputLabel>{readableType(props.field.emailType)}</InputLabel>
          <FormLabel>
            {props.handlePreferredChange && (
              <Button
                size="xs"
                variant="secondary"
                onClick={(e) => {
                  props.handlePreferredChange(props.field.emailType)
                }}
                disabled={props.field.preferred}
              >
                {t("physicianEdit.form.preferredCheckbox")}
              </Button>
            )}
          </FormLabel>
        </Flex>
        <Flex>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<EmailIcon color="text" />}
            />
            <Input
              backgroundColor="white"
              color="text"
              fontSize="14px"
              {...props.register(`${fieldName}.email`, {
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Please use a valid email",
                },
              })}
            />
          </InputGroup>
        </Flex>
      </FormControl>
    </StyledBox>
  )
}
