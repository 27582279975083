import { Icon } from "@chakra-ui/react"
import React from "react"

export const EmailIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M14.2222 2.66669H1.7778C1.54206 2.66669 1.31596 2.76034 1.14927 2.92704C0.982566 3.09374 0.888916 3.31983 0.888916 3.55558V12.4445C0.888916 12.6802 0.982566 12.9063 1.14927 13.073C1.31596 13.2397 1.54206 13.3334 1.7778 13.3334H14.2222C14.458 13.3334 14.6841 13.2397 14.8508 13.073C15.0175 12.9063 15.1111 12.6802 15.1111 12.4445V3.55558C15.1111 3.31983 15.0175 3.09374 14.8508 2.92704C14.6841 2.76034 14.458 2.66669 14.2222 2.66669ZM13.5378 12.4445H2.51558L5.62669 9.22669L4.98669 8.60891L1.7778 11.9289V4.23113L7.30225 9.72891C7.46879 9.89446 7.69408 9.98739 7.92892 9.98739C8.16375 9.98739 8.38904 9.89446 8.55558 9.72891L14.2222 4.09335V11.8711L10.9511 8.60002L10.3245 9.22669L13.5378 12.4445ZM2.36003 3.55558H13.5022L7.92892 9.0978L2.36003 3.55558Z"
    />
  </Icon>
)
