import { extendTheme, ThemeComponents } from "@chakra-ui/react"

const colors = {
  primary: "#06357A",
  primaryMuted: "rgba(9,52,122, 0.05)",
  translucent: {
    100: "rgba(255, 255, 255, 0.15)",
    200: "rgba(255, 255, 255, 0.25)",
    300: "rgba(255, 255, 255, 0.35)",
    400: "rgba(255,255,255,0.45)",
    500: "rgba(255, 255, 255, 0.55)",
    600: "rgba(255, 255, 255, 0.65)",
    700: "rgba(255, 255, 255, 0.75)",
    800: "rgba(255, 255, 255, 0.85)",
    900: "rgba(255, 255, 255, 0.95)",
  },
  // gray: { 50: "#F1F1F2", 100: "#D6D7D8", 200: "#D6D7D8", 300: "#C3CAD1" },
  // gray: { 1: "#F2F1F0", 3: "#BBBBBB" },
  // gray 50 is the new grey4 on figma

  text: "#413B36",
  textMuted: "#777777",
  background: "#F1F1F2",
  accentRed: "#ED3341",
  accentPurple: "#967C91",
  secondary: "#736B6C",
  blue: "#082668",
}

const fonts = {
  body: "Roboto",
  heading: "Roboto",
}

const Button = {
  baseStyle: {
    fontWeight: "medium",
    borderRadius: 0,
  },
  variants: {
    navigation: {
      color: "white",
      backgroundColor: "translucent.100",
      border: "1px solid",
      h: "32px",
      borderRadius: 2,
      _hover: {
        backgroundColor: "translucent.200",
      },
    },
    outline: (props) => ({
      color: "primary",
      borderColor: "primary",
      backgroundColor: "white",
      h: "50px",
      _hover: {
        backgroundColor: "primaryMuted",
      },
    }),
    secondary: (props) => ({
      backgroundColor: "gray.50",
      color: "primary",
      px: 2,
      h: "32px",
      _hover: {
        backgroundColor: "gray.100",
      },
    }),
  },
}

const Checkbox = {
  baseStyle: {
    control: {
      borderColor: "gray.100",
      bg: "white",
      _checked: {
        bg: "primary",
        borderColor: "primary",
        color: "white",
      },
      _hover: {
        bg: "white",
        borderColor: "gray.200",
      },
      _disabled: {
        borderColor: "gray.100",
        bg: "grey.50",
      },
    },
    label: {
      color: "text",
    },
  },
}

const Input = {
  sizes: {
    md: {
      field: {
        borderRadius: 0,
      },
      addon: {
        borderRadius: 0,
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: "gray.100",
        _focus: {
          borderColor: "primary",
          boxShadow: "0 0 0 1px primary",
        },
        _placeholder: {
          color: "textMuted",
        },
      },
    },
  },
}

const Link = {
  baseStyle: {
    color: "primary",
  },
}

const Select = { ...Input, baseStyle: { icon: { color: "textMuted" } } }

const Tabs = {
  variants: {
    line: {
      tablist: {
        borderBottomStyle: "none",
        backgroundColor: "white",
      },
      tab: {
        color: "textMuted",
        fontWeight: "semibold",
        _selected: {
          color: "primary",
          borderColor: "primary",
        },
      },
    },
  },
}

const components: ThemeComponents = {
  Button,
  Checkbox,
  Input,
  Link,
  Select,
  Tabs,
}

export const theme = extendTheme({ colors, fonts, components })
