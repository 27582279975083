import { flow, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { DirectorySectionModel } from "./directory-section"

export const FacilityModel = types
  .model("FacilityModel")
  .props({
    id: types.identifierNumber,
    name: types.optional(types.string, ""),
    directorySections: types.array(DirectorySectionModel),
    directorySectionPage: types.optional(types.number, 1),
    hasMoreDirectorySectionPage: types.optional(types.boolean, true),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    saveContactsAndRemapDirectorySectionContactIds(directorySectionData) {
      const { contactStore } = self.rootStore
      R.forEach((directorySection) => {
        directorySection["contacts"] = R.map((contact) => {
          // store each contact in the contacStore
          contactStore.updateContactData(contact)
          // return just the ids in an array for the references in this store
          return contact.id
        }, directorySection["contacts"])
      }, directorySectionData)
      return directorySectionData
    },
  }))
  .actions((self) => ({
    getDirectorySections: flow(function* () {
      const response: any = yield self.environment.api.getDirectorySections(
        self.id,
        self.directorySectionPage
      )
      if (response.ok) {
        const { data } = response
        if (!R.isEmpty(data)) {
          const dsData = self.saveContactsAndRemapDirectorySectionContactIds(
            data
          )
          self.directorySections = self.directorySections.concat(dsData) as any
          self.directorySectionPage += 1
        } else {
          self.hasMoreDirectorySectionPage = false
        }
      }
    }),
    getDirectorySectionIfNeeded: flow(function* (id) {
      const directorySection = R.find(R.propEq("id", id))(
        self.directorySections
      )
      if (!directorySection) {
        const response: any = yield self.environment.api.getDirectorySection(id)
        if (response.ok) {
          const dsData = R.head(
            self.saveContactsAndRemapDirectorySectionContactIds([response.data])
          )
          self.directorySections.push(dsData)
        }
      }
    }),
  }))
