import { Icon } from "@chakra-ui/react"
import React from "react"

export const CheckIcon = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="none"
      d="M2.66669 8L6.66669 12L13.3334 4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
