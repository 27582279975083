import { types } from "mobx-state-tree"
import { createFavoritable } from "../compositions/favoritable"
import { withRootStore } from "../lib"

export const ContactModel = types.compose(
  createFavoritable("favoriteContacts"),
  types
    .model("ContactModel")
    .props({
      id: types.identifierNumber,
      name: types.optional(types.string, ""),
      number: types.optional(types.string, ""),
      contactType: types.optional(types.string, ""),
      directorySectionId: types.maybeNull(types.number),
      facilityName: types.optional(types.string, ""),
    })
    .extend(withRootStore())
    .views((self) => ({
      get isFavoritedWithLocal() {
        const {
          sessionStore: { loggedIn },
          favoriteStore: { localFavoriteContacts },
        } = self.rootStore
        if (loggedIn) {
          return self.isFavorited
        } else {
          return localFavoriteContacts.has(self.id)
        }
      },
    }))
    .actions((self) => ({}))
)
