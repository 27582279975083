import { Box, Center, Container, Flex, Text, HStack } from "@chakra-ui/react"
import styled from "@emotion/styled"
import * as R from "ramda"
import React from "react"
import { AddToFavouritesButton } from "../../../shared/add-to-favourites-btn"
import { useMst } from "../../../../setup"

const SpecialtyText = styled(Text)``
SpecialtyText.defaultProps = {
  color: "primary",
  fontWeight: "700",
  fontSize: "16px",
}

export const Header = (props) => {
  const { favoriteStore } = useMst()

  const handleClick = () => {
    favoriteStore.createFavorite("Clinic", props.clinicId)
  }

  const renderSpecialties = () => {
    return R.map(
      (specialty) => (
        <SpecialtyText key={`p-specialty-${specialty.id}`}>
          {specialty.name}
        </SpecialtyText>
      ),
      props.specialties
    )
  }

  return (
    <Flex
      bg="background"
      ml="-16px"
      mr="-16px"
      justifyContent="space-between"
      p="32px"
    >
      <Center>
        <Box mt="8px" mb="8px">
          <Text color="text" fontWeight="700" fontSize="24px">
            {props.name}
          </Text>
          <Box mt="8px" mb="8px">
            {props.specialties && renderSpecialties()}
          </Box>
          <Box mt="8px" mb="8px">
            {!props.isFavorited && (
              <AddToFavouritesButton onClick={handleClick} />
            )}
          </Box>
        </Box>
      </Center>
    </Flex>
  )
}
