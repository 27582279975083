import { types } from "mobx-state-tree"
import {
  IClinics,
  IDepartments,
  IDivisions,
  IFacilities,
  ISpecialties,
} from "../types"

export const FormOptionsModel = types
  .model("FormOptionsModel")
  .props({
    specialties: types.array(types.frozen<ISpecialties>()),
    clinics: types.array(types.frozen<IClinics>()),
    facilities: types.array(types.frozen<IFacilities>()),
    departments: types.array(types.frozen<IDepartments>()),
    divisions: types.array(types.frozen<IDivisions>()),
  })
  .views((self) => ({}))
  .actions((self) => ({}))
