import { flow, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { FacilityModel } from "../models/facility"

export const FacilityStoreModel = types
  .model("FacilityStoreModel")
  .props({
    facilities: types.map(FacilityModel),
    selectedFacility: types.maybeNull(types.reference(FacilityModel)),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    getFacilities: flow(function* () {
      const response: any = yield self.environment.api.getFacilities()
      if (response.ok) {
        R.map((facility) => {
          self.facilities.put(facility)
        }, response.data)
        // set default facility as user's home one if needed
      } else {
        //draft an alert or something for now unable to retrieve response
      }
    }),
    setSelectedFacility: flow(function* (facilityId) {
      if (facilityId) {
        // only fetch if we haven't previously fetched any directory sections for this facility
        self.selectedFacility = facilityId
        if (R.isEmpty(self.selectedFacility.directorySections)) {
          const response: any = yield self.environment.api.getFacility(
            facilityId
          )
          if (response.ok) {
            self.facilities.put(response.data)
            yield self.selectedFacility.getDirectorySections()
            return self.selectedFacility
          }
        }
      } else {
        self.selectedFacility = null
        return null
      }
    }),
  }))
  .actions((self) => ({
    setSelectedFacilityToFirst() {
      const firstFacilityId = R.pipe(Array.from, R.head)(self.facilities.keys())
      self.setSelectedFacility(firstFacilityId)
    },
  }))
  .actions((self) => ({
    setDefaultSelectedFacility() {
      const {
        sessionStore,
        userStore: { profile: primaryPractice },
      } = self.rootStore
      if (sessionStore.isLoggedIn) {
        const primaryPracticeId = primaryPractice?.id
        if (self.facilities.has(primaryPracticeId)) {
          self.selectedFacility = primaryPracticeId
        } else {
          self.setSelectedFacilityToFirst()
        }
      } else {
        self.setSelectedFacilityToFirst()
      }
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      yield self.getFacilities()
      self.setDefaultSelectedFacility()
    }),
  }))
export interface IFacilityStore extends Instance<typeof FacilityStoreModel> {}
