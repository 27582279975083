import { Box, Divider, Text } from "@chakra-ui/react"
import React from "react"

export const TextBox = (props) => {
  return (
    <Box mt="16px" mb="32px">
      <Text
        color="primary"
        fontWeight="700"
        fontSize="18px"
        letterSpacing="1px"
        textTransform="uppercase"
      >
        {props.label}
      </Text>
      <Divider border="3px solid" borderColor="accentRed" />

      <Text fontSize="16px" color="#222222">
        {props.description}
      </Text>
    </Box>
  )
}
