import { Icon } from "@chakra-ui/react"
import React from "react"

export const BookmarkIcon = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M12.8651 6.63721V2.4093C12.8651 1.63207 12.233 1 11.4558 1H4.4093C3.63207 1 3 1.63207 3 2.4093V15L7.93256 11.7121L12.8651 15V6.63721Z"
      fill="currentColor"
    />
  </Icon>
)
