import React from "react"
import { Link } from "react-router-dom"

export const LocationLink = (props) => {
  switch (props.locationType) {
    case "CLINIC":
      return <Link to={`/clinics/${props.locationId}`}>{props.children}</Link>
      break
    default:
      return <>{props.children}</>
      break
  }
}
