import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link as ChakraLink,
  Spacer,
  Text,
} from "@chakra-ui/react"
import styled from "@emotion/styled"
import debounce from "lodash.debounce"
import { observer } from "mobx-react-lite"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom"
import { useMst } from "../setup/root"
import { ClinicIndexScreen } from "./domains/clinics/clinic-index/clinic-index-screen"
import { DirectoryShow } from "./domains/directory"
import { PhysicianIndexScreen } from "./domains/physicians/physician-index/physician-index-screen"
import { FilterOptionsModal } from "./filter-options-modal"
import { LoadingSpinner } from "./shared/base/loading-spinner"
import {
  MedicalIcon,
  DirectoryIcon,
  FilterIcon,
  SearchIcon,
  UserIcon,
} from "./shared/icons"
import { CloseIcon } from "./shared/icons/close-icon"

export const Home = observer(() => {
  const { t } = useTranslation()
  const { physicianStore, clinicStore, contactStore } = useMst()
  const [searchTerm, setSearchTerm] = useState("")

  const [specialtyFilter, setSpecialtyFilter] = useState([])
  const [facilityFilter, setFacilityFilter] = useState([])
  const filterMap = (filter) =>
    filter.map((specialty) => specialty.value).join(",")

  let { path, url } = useRouteMatch()

  //useref will prevent redeclaration
  const physiciansDebouncedSearch = useRef(
    debounce((term, specialties, facilityIds) => {
      physicianStore.search(term, specialties, facilityIds)
    }, 500)
  ).current

  const clinicsDebouncedSearch = useRef(
    debounce((term, specialties) => {
      clinicStore.search(term, specialties)
    }, 500)
  ).current

  const directoryDebouncedSearch = useRef(
    debounce((term) => {
      contactStore.search(term)
    }, 500)
  ).current

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
    handleSearch({ searchTerm: e.target.value }) //use options as the reliable way to evaluate the search term as setState will not have called and we do not use a callback method
  }

  const pathColor = (activePath) => {
    if (path === activePath) {
      return "accentRed"
    } else {
      return "primary"
    }
  }

    const pathColorIcon = (activePath) => {
    if (path === activePath) {
      return "primary"
    } else {
      return "textMuted"
    }
  }

  const handleSearch = (options = {}) => {
    switch (path) {
      case "/clinics":
        clinicsDebouncedSearch(
          options["searchTerm"] || searchTerm,
          filterMap(options["specialtyFilter"] || specialtyFilter)
        )
        break

      case "/directory":
        directoryDebouncedSearch(options["searchTerm"] || searchTerm)
        break

      //take into account home path / and /physicians
      default:
        physiciansDebouncedSearch(
          options["searchTerm"] || searchTerm,
          filterMap(options["specialtyFilter"] || specialtyFilter),
          filterMap(options["facilityFilter"] || facilityFilter)
        )
        break
    }
  }

  const clearSearch = () => {
    setSearchTerm("")
    setSpecialtyFilter([])
    setFacilityFilter([])
    contactStore.clearSearch()
    clinicStore.clearSearch()
    physicianStore.clearSearch()
    //set all the loading spinners to false
  }

  const resetFiltersButton = () => {
    return (
      <Center>
        <ChakraLink
          fontWeight="600"
          onClick={clearSearch}
          color="textMuted"
          pt={2}
          pb={2}
        >
          {t("shared.resetFilters")}
        </ChakraLink>
      </Center>
    )
  }

  return (
    <Box>
      <Box position="fixed" w="100%" bgColor="white" zIndex="1">
        <InputGroup>
          <InputLeftElement
            height={65}
            children={<SearchIcon color="primary" />}
          />
          <Input
            height={65}
            fontSize={16}
            placeholder="Search"
            border="0px"
            value={searchTerm}
            onChange={handleInputChange}
          ></Input>

          <ChakraLink onClick={clearSearch} ml="8px" mr="8px" p={5}>
            <CloseIcon />
          </ChakraLink>

          {path !== "/directory" && (
            <FilterOptionsModal
              specialtyFilter={specialtyFilter}
              setSpecialtyFilter={setSpecialtyFilter}
              facilityFilter={facilityFilter}
              setFacilityFilter={setFacilityFilter}
              handleSearch={handleSearch}
              includeFacilities={path == "/" || path == "/physicians"}
              clearSearch={clearSearch}
            />
          )}
        </InputGroup>
        <Divider />

        <StyledTabsList>
          <StyledNavLink
            to="/physicians"
            activeStyle={{ borderBottom: "1px solid #09347A" }}
          >
            <Center width={"100%"}>
              <UserIcon color={pathColorIcon("/physicians")} />
              <Text
                p={2}
                fontSize={18}
                color={pathColor("/physicians")}
                fontWeight="700"
              >
                {t("home.people")}
              </Text>
            </Center>
          </StyledNavLink>

          <StyledNavLink
            to="/clinics"
            activeStyle={{ borderBottom: "1px solid #09347A" }}
          >
            <Center width={"100%"}>
              <MedicalIcon color={pathColorIcon("/clinics")} />
              <Text
                p={2}
                fontSize={18}
                color={pathColor("/clinics")}
                fontWeight="700"
              >
                {t("home.clinics")}
              </Text>
            </Center>
          </StyledNavLink>

          <StyledNavLink
            to="/directory"
            activeStyle={{ borderBottom: "1px solid #09347A" }}
          >
            <Center width={"100%"}>
              <DirectoryIcon color={pathColorIcon("/directory")} />
              <Text
                p={2}
                fontSize={18}
                color={pathColor("/directory")}
                fontWeight="700"
              >
                {t("home.places")}
              </Text>
            </Center>
          </StyledNavLink>
        </StyledTabsList>
      </Box>

      <Switch>
        <Route exact path="/clinics">
          <Box h="110px"></Box>
          {specialtyFilter.length > 0 && (
            <>
              <Container
                pt={2}
                pb={2}
                bg={"primary"}
                textAlign={"center"}
                color={"white"}
                position="fixed"
              >
                <Flex>
                  <Spacer />
                  <Center>
                    <FilterIcon color="white" />
                    <Text ml="4px">{`${specialtyFilter.length} Filter(s) applied`}</Text>
                  </Center>
                  <Spacer />
                  <ChakraLink onClick={clearSearch} color="white">
                    {t("shared.resetFilters")}
                  </ChakraLink>
                  <Spacer />
                </Flex>
              </Container>
              <Box h="30px"></Box>
            </>
          )}
          <Center>
            <LoadingSpinner
              visible={clinicStore.searchLoading}
              key={"clinic-spinner"}
            />
            <ClinicIndexScreen
              clearSearch={clearSearch}
              displayAll={searchTerm == "" && specialtyFilter.length == 0}
            />
          </Center>
        </Route>
        <Route exact path="/directory">
          <>
            <Center>
              <LoadingSpinner
                visible={contactStore.searchLoading}
                key={"directory-spinner"}
              />
            </Center>
            <DirectoryShow
              clearSearch={clearSearch}
              displayAll={searchTerm == ""}
            />
          </>
        </Route>
        <Route exact path={["/", "/physicians"]}>
          <Box h="110px" />
          {specialtyFilter.length + facilityFilter.length > 0 && (
            <>
              <Container
                pt={2}
                pb={2}
                bg={"primary"}
                textAlign={"center"}
                color={"white"}
                position="fixed"
                zIndex="1"
              >
                <Flex>
                  <Spacer />
                  <Center>
                    <FilterIcon color={"white"} />
                    <Text ml="4px">
                      {`${
                        specialtyFilter.length + facilityFilter.length
                      }   Filter(s) applied`}{" "}
                    </Text>
                  </Center>
                  <Spacer />
                  <ChakraLink onClick={clearSearch} color="white">
                    {t("shared.resetFilters")}
                  </ChakraLink>
                  <Spacer />
                </Flex>
              </Container>
              <Box h="30px"></Box>
            </>
          )}
          <Center>
            <LoadingSpinner
              visible={physicianStore.searchLoading}
              key={"physician-spinner"}
            />
            <PhysicianIndexScreen
              clearSearch={clearSearch}
              displayAll={
                searchTerm == "" &&
                facilityFilter.length == 0 &&
                specialtyFilter.length == 0
              }
            />
          </Center>
        </Route>
      </Switch>
    </Box>
  )
})
const StyledTabsList = styled.div`
  display: flex;
  margin-left: 10px;
`
const StyledNavLink = styled(NavLink)`
  display: flex;
  width: 33%;
`
