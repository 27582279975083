import { Instance } from "mobx-state-tree"
import persist from "mst-persist"
import { createContext, useContext } from "react"
import { setupEnvironment } from "."
import { localCache, STORE_KEY } from "../services/api/local-cache"
import * as Monitors from "../services/api/monitors"
import { RootStoreModel } from "../stores/root-store"

const initialState = {}

const environment = setupEnvironment()
export const rootStore = RootStoreModel.create(initialState, environment)
persist(STORE_KEY, rootStore.favoriteStore, {
  storage: localCache,
  jsonify: false,
  whitelist: ["localFavoriteContacts"], // only these keys will be persisted
})

// add API Monitors
const { api } = environment
const { uiStore } = rootStore
Monitors.addFlashMessageMonitor(api, uiStore)

if (process.env.NODE_ENV !== "production") {
  console.tron.trackMstNode(rootStore)
}

export type RootInstance = Instance<typeof RootStoreModel>
const RootStoreContext = createContext<null | RootInstance>(null)

export const Provider = RootStoreContext.Provider
export function useMst() {
  const store = useContext(RootStoreContext)
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider")
  }
  return store
}
