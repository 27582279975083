import { Box, Button, Container, Stack, Text } from "@chakra-ui/react"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import * as R from "ramda"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useMst } from "../../../../setup"
import { resetToHomeScreen } from "../../../../utils/utils"
import { LoadingSpinner } from "../../../shared/base/loading-spinner"
import { Section } from "../physician-show"
import {
  AdminContact,
  Emails,
  Header,
  LocationsSection,
  PersonalSection,
  PhoneNumbers,
  ProfileSection,
} from "./"

type FormValues = {
  firstName: string
  lastName: string
  adminName: string
  mspid: string
  phoneNumbers: Array<{
    id: number
    phoneNumber: string
    extension: string
    smsPreferred: boolean
    phoneType: string
    preferred: boolean
  }>
  specialties: Array<{
    id: number
    name: string
  }>
  phoneNumbersAttributes: Array<{
    id: number
    phoneNumber: string
    extension: string
    smsPreferred: boolean
    phoneType: string
    preferred: boolean
  }>
  physicianSpecialtiesAttributes: Array<{
    id: number
    specialtyId: number
    _destroy: string
  }>
}
export const PhysicianEditScreen = observer((props) => {
  let history = useHistory()
  const { t } = useTranslation()
  const { formOptionsStore, userStore } = useMst()

  const methods = useForm()

  useEffect(() => {
    formOptionsStore.getFormOptions()

    methods.reset({ ...toJS(userStore.profile), profileImage: undefined })
  }, [userStore.profile, formOptionsStore.formOptions])

  if (
    R.isNil(userStore.profile && userStore.profile.id) ||
    R.isNil(formOptionsStore.formOptions)
  ) {
    return <LoadingSpinner visible={true} />
  }

  const mapValuesForFacility = (facility) => ({
    ...facility,
    id:
      typeof facility["id"] == "string" && facility["id"].startsWith("new-")
        ? ""
        : facility["id"],
  })
  const mapValuesForOffice = (office) => ({
    id:
      typeof office["id"] == "string" && office["id"].startsWith("new-")
        ? ""
        : office["id"],
    ownerType: office["ownerType"],
    _destroy: office["_destroy"],
    ownerAttributes: {
      id: office["ownerAttributesId"],
      name: office["ownerAttributesName"],
      addressAttributes: {
        id: office["ownerAttributesAddressAttributesId"],
        streetAddress: office["ownerAttributesAddressAttributesStreetAddress"],
        postalCode: office["ownerAttributesAddressAttributesPostalCode"],
        city: office["ownerAttributesAddressAttributesCity"],
        province: office["ownerAttributesAddressAttributesProvince"],
      },
      officePhoneNumberAttributes: {
        id: office["ownerAttributesOfficePhoneNumberAttributesId"],
        phoneNumber:
          office["ownerAttributesOfficePhoneNumberAttributesPhoneNumber"],
        phoneType: "office",
      },
      faxPhoneNumberAttributes: {
        id: office["ownerAttributesFaxPhoneNumberAttributesId"],
        phoneNumber:
          office["ownerAttributesFaxPhoneNumberAttributesPhoneNumber"],
        phoneType: "fax",
      },
    },
  })

  const onSubmit = (values) => {
    //need to filter out any ids with 'new-' so rails knows to create them
    values["physicianOfficesLocationsAttributes"] = values[
      "physicianOfficesLocationsAttributes"
    ].map((office) => {
      return mapValuesForOffice(office)
    })

    //need to fetch the first image
    if (values["profileImage"].length > 0) {
      values["profileImage"] = values["profileImage"][0]
    } else {
      //do not delete the existing image if there is no value set
      delete values["profileImage"]
    }
    values["physicianFacilitiesLocationsAttributes"] = values[
      "physicianFacilitiesLocationsAttributes"
    ].map((facility) => {
      return mapValuesForFacility(facility)
    })

    //ASSUMES REDIRECT AFTER SAVE, DO NOT NEED TO RELOAD FULL FORM FROM NESTED IDS.
    userStore.updateProfile(userStore.profile.id, values).then((success) => {
      if (success) {
        resetToHomeScreen(history)
      }
    })
  }

  return (
    <Container>
      <Stack>
        <Text color="text" fontWeight="700" fontSize="24px" mt="10px">
          {t("physician.editPageHeader")}
        </Text>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Header existingProfileUrl={userStore.profile.profileImage} />
            <ProfileSection />

            <Box bgColor="background" mr="-16px" ml="-16px" p="16px" mb="24px">
              <Text
                fontSize="24px"
                fontWeight="700"
                color="text"
                mt="16px"
                mb="16px"
              >
                {t("physicianEdit.form.contactInformation")}
              </Text>
              <Section title={"PHONE"}>
                <Text color="accentRed" fontSize="12px">
                  {t("physicianEdit.form.fillRelevantFields")}
                </Text>
                <PhoneNumbers />
                <Emails />
                <AdminContact />
              </Section>
            </Box>

            <LocationsSection />
            <PersonalSection />
            <Button type="submit" variant="outline" isFullWidth mt={3} mb={30}>
              {t("physician.saveChanges")}
            </Button>
          </form>
        </FormProvider>
      </Stack>
    </Container>
  )
})
