import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
  HStack,
  Text,
} from "@chakra-ui/react"
import * as R from "ramda"
import React from "react"
import { AddressIcon, ClickableIcon } from "../../../shared/icons"
import { ContactInfoIcon } from "../../../shared/icons/contact-info-icon"
import { LocationLink } from "./location-link"

export interface IPracticeLocationProps {
  locationId?: number
  locationType: string
  locationName: string
  streetAddress?: string
  telephoneNumber?: string
  city?: string
  province?: string
  fax?: string
  phoneNumbers?: []
  cityAndProvince: string
  postalCode: string
  details?: string
}

export const PracticeLocation = (props: IPracticeLocationProps) => {
  const renderPhoneNumbers = () => {
    return R.map((number) => {
      const { icon, phoneNumber, id } = number
      return (
        phoneNumber && (
          <ClickableIcon
            contactType={icon}
            contactValue={phoneNumber}
            key={`ci-${id}`}
          >
            <HStack key={`p-number-${id}`}>
              <ContactInfoIcon icon={icon} color="primary" />
              <Text fontSize="16px" fontWeight="400" ml={2}>
                {phoneNumber}
              </Text>
            </HStack>
          </ClickableIcon>
        )
      )
    }, props.phoneNumbers)
  }

  const {
    locationName: ln,
    locationType: lt,
    streetAddress,
    locationId,
    city,
    province,
    postalCode,
    details,
  } = props
  const locationName = ln.toUpperCase()
  const locationType = lt.toLocaleUpperCase()

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {locationName}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>
          <Grid
            templateColumns="repeat(2, 1fr)"
            bg="background"
            mb="20px"
            borderRadius="5"
            p="10px"
          >
            <LocationLink locationType={locationType} locationId={locationId}>
              <GridItem>
                <Text fontSize="16px" color={"primary"} fontWeight="700">
                  {ln}
                </Text>
                <Text fontSize="14px" color={"textMuted"} fontWeight="700">
                  {locationType}
                </Text>
              </GridItem>
            </LocationLink>

            <GridItem>
              {streetAddress && (
                <>
                  <HStack>
                    <AddressIcon color="primary" />
                    <Text fontSize="16px" fontWeight="400" color="200" ml={1}>
                      {streetAddress}
                    </Text>
                  </HStack>
                  <Text fontSize="16px" fontWeight="400" color="200" ml={6}>
                    {`${city}, ${province}`}
                  </Text>
                  <Text fontSize="16px" fontWeight="400" color="200" ml={6}>
                    {postalCode}
                  </Text>
                  <Text fontSize="16px" fontWeight="400" color="200" ml={6}>
                    {details}
                  </Text>
                </>
              )}

              {renderPhoneNumbers()}
            </GridItem>
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
