import { Button } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { PlusIcon } from "./icons/plus-icon"

export const AddToFavouritesButton = (props) => {
  const { t } = useTranslation()
  return (
    <Button
      color="accentPurple"
      leftIcon={<PlusIcon color="accentPurple" />}
      variant={"navigation"}
      size="xs"
      fontWeight="700"
      fontSize="16px"
      onClick={props.onClick}
    >
      {t("shared.addToFavorites")}
    </Button>
  )
}
