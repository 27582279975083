import { Box, Center, Container, Spinner } from "@chakra-ui/react"
import { values } from "mobx"
import { observer } from "mobx-react-lite"
import * as R from "ramda"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useMst } from "../../../../setup"
import { NoResultsMessage } from "../../../shared/base/no-results-message"
import { PhysicianCard } from "./index"

export const PhysicianIndexScreen = observer(({ displayAll, clearSearch }) => {
  const { physicianStore } = useMst()
  const { t } = useTranslation()
  const {
    physicians,
    hasSearchResults,
    searchResults,
    getResources: getPhysicians,
    hasMorePages,
  } = physicianStore
  const displayPhysicians = values(physicians)

  useEffect(() => {
    clearSearch()
    // return () => {
    //   clearSearch()
    // }
  }, [])

  const renderPhysicians = (physicians) => {
    return R.map(
      (physician) => (
        <Box mt={2} mb={2} key={`box-${physician.id}`}>
          <PhysicianCard
            key={`p-card-${physician.id}`}
            physicianId={physician.id}
            profileImage={physician.profileImage}
            name={physician.formatTitle}
            primaryPractice={physician.primaryPractice?.name}
            specialties={physician.specialties}
            preferredPhone={physician.preferredPhone}
          />
        </Box>
      ),
      physicians
    )
  }

  const renderInifiniteScrollPhysicians = () => {
    return (
      <InfiniteScroll
        dataLength={displayPhysicians.length} //This is important field to render the next data
        next={getPhysicians}
        hasMore={hasMorePages}
        loader={
          <Center>
            <Spinner position="fixed" size="md" />
          </Center>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>{t("home.infiniteScrollEndMessage")}</b>
          </p>
        }
      >
        {renderPhysicians(displayPhysicians)}
      </InfiniteScroll>
    )
  }

  return (
    <Container bg="background" minHeight={"100vh"} pr={0} pl={0}>
      {displayAll ? (
        renderInifiniteScrollPhysicians()
      ) : hasSearchResults ? (
        renderPhysicians(searchResults)
      ) : (
        <NoResultsMessage />
      )}
    </Container>
  )
})
