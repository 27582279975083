import { Icon } from "@chakra-ui/react"
import React from "react"

export const SearchIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.41437 12.8287C8.83754 12.8284 10.2197 12.352 11.3408 11.4753L14.8655 15L15.9992 13.8663L12.4745 10.3416C13.3517 9.22038 13.8284 7.83791 13.8287 6.41437C13.8287 2.87765 10.9511 0 7.41437 0C3.87765 0 1 2.87765 1 6.41437C1 9.95109 3.87765 12.8287 7.41437 12.8287ZM7.41437 1.60359C10.0675 1.60359 12.2251 3.76123 12.2251 6.41437C12.2251 9.06751 10.0675 11.2251 7.41437 11.2251C4.76123 11.2251 2.60359 9.06751 2.60359 6.41437C2.60359 3.76123 4.76123 1.60359 7.41437 1.60359Z"
      fill="currentColor"
    />
  </Icon>
)
