import { Box, HStack, Text } from "@chakra-ui/react"
import * as R from "ramda"
import React from "react"
import { AddressIcon, ClickableIcon } from "../../../shared/icons"
import { ContactInfoIcon } from "../../../shared/icons/contact-info-icon"

export const Location = (props) => {
  const renderPhoneNumbers = () => {
    return R.map(
      (number) => (
        <ClickableIcon
          contactValue={number.phoneNumber}
          contactType={number.phoneType}
          key={`ci-number-${number.id}`}
        >
          <HStack key={`p-number-${number.id}`} pb={1} pt={1} pl={2}>
            <ContactInfoIcon icon={number.phoneType} color="primary" />
            <Text fontSize="14px" ml={2} color="#222222" fontWeight="400">
              {number.phoneNumber}
            </Text>
          </HStack>
        </ClickableIcon>
      ),
      props.phoneNumbers
    )
  }

  return (
    <>
      <Text fontSize="24px" pl={2} mt={3} mb={3} color="text">
        Location
      </Text>

      <Box bg="background" borderRadius="5" m={2} p={2}>
        {props.address && (
          <HStack alignItems="baseline" ml={2}>
            <Box>
              <AddressIcon color="primary" />
            </Box>
            <Box>
              <Text fontSize="16px" fontWeight="500" color="primary">
                {props.streetAddress}
              </Text>
              <Text fontSize="16px" fontWeight="500" color="primary">
                {`${props.city}, ${props.province}`}
              </Text>
              <Text fontSize="16px" fontWeight="500" color="primary">
                {props.postalCode}
              </Text>
            </Box>
          </HStack>
        )}
        {props.phoneNumbers && renderPhoneNumbers()}
      </Box>
    </>
  )
}
