import { types } from "mobx-state-tree"

export const PhoneModel = types
  .model("PhoneModel")
  .props({
    id: types.identifierNumber,
    phoneNumber: types.optional(types.string, ""),
    phoneType: types.optional(types.string, ""),
    extension: types.maybeNull(types.string),
    smsPreferred: types.maybeNull(types.boolean),
    preferred: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get label() {
      return self.phoneType.charAt(0).toUpperCase() + self.phoneType.slice(1)
    },
    get value() {
      return self.phoneNumber
    },
    get icon() {
      return self.phoneType
    },
  }))
  .actions((self) => ({}))
