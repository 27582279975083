import { Box, Flex, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useMst } from "../../../../setup"
import { Autocomplete } from "./"
import { ConnectForm } from "./index"

export const SpecialtiesSection = () => {
  const { t } = useTranslation()

  const { formOptionsStore } = useMst()

  return (
    <ConnectForm>
      {({ register, getValues, watch, setValue, formState: { errors } }) => {
        return (
          <Box mb={5}>
            <Flex>
              <Text
                fontSize="14px"
                color="primary"
                letterSpacing="1px"
                fontWeight="700"
              >
                {t("physicianEdit.form.specialtyHeader")}
              </Text>
              <Text color="accentRed">*</Text>
            </Flex>
            <Text color="accentRed" fontSize="12px">
              {t("physicianEdit.form.specialtyInstructions")}
            </Text>

            <Autocomplete
              selectedItems={watch("physicianSpecialtiesAttributes")}
              setSelectedItems={(newValue) =>
                setValue("physicianSpecialtiesAttributes", newValue)
              }
              attributeForValue={"specialtyId"}
              possibleValues={formOptionsStore.formOptions.specialties}
              attributeForLabel={"name"}
              placeholder={t("physicianEdit.form.typeSpecialty")}
            />
            <Text color="accentRed" fontSize="12px">
              {t("physicianEdit.form.missingDataSpecialty")}
              <a href={`mailto:pass@providencehealth.bc.ca`}>
                pass@providencehealth.bc.ca
              </a>
            </Text>
          </Box>
        )
      }}
    </ConnectForm>
  )
}
