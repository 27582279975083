import { values } from "mobx"
import { types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"

/* 
  Favoritable
  Meant to implement favorit-ing functionality

  params
    favoriteStoreProp - the state slice in favoriteStore to look at

*/
export const createFavoritable = (favoriteStoreProp) => {
  return types
    .model({})
    .extend(withRootStore())
    .extend(withEnvironment())
    .views((self) => ({
      get isFavorited() {
        return R.contains(
          self.id,
          R.pluck(
            "favoritableId",
            values(self.rootStore.favoriteStore[favoriteStoreProp])
          )
        )
      },
    }))
}
