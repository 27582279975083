import { Box, FormControl, Select, Stack, Text } from "@chakra-ui/react"
import * as R from "ramda"
import React from "react"
import { useTranslation } from "react-i18next"
import { useMst } from "../../../../setup"
import { ConnectForm } from "./ConnectForm"
import { InputLabel } from "./input-label"

export const DepartmentAndDivision = () => {
  const { t } = useTranslation()
  const { formOptionsStore } = useMst()

  return (
    <ConnectForm>
      {({ register, getValues, watch, setValue, formState: { errors } }) => {
        const watchDepartmentId = watch("departmentId")
        const customOnDepartmentChange = (e) => {
          setValue("departmentId", e.target.value)
          setValue("divisionId", null)
        }
        const divisionOptions = formOptionsStore.formOptions.divisions.filter(
          (division) => division.departmentId == watchDepartmentId
        )
        return (
          <Box mb={2}>
            <Stack>
              <FormControl className="department" isRequired mb="16px">
                <InputLabel>{t("physician.department")}</InputLabel>
                <Select
                  {...register("departmentId", {
                    required: "This is a required field",
                  })}
                  placeholder={t("physician.departmentPlaceholder")}
                  onChange={customOnDepartmentChange}
                >
                  {R.map(
                    (department) => (
                      <option
                        key={`dep-${department.id}`}
                        value={department.id}
                      >
                        {department.name}
                      </option>
                    ),
                    formOptionsStore.formOptions.departments
                  )}
                </Select>
              </FormControl>
              {errors.departmentId && (
                <Text color="red" fontSize="14px">
                  {errors.departmentId.message}
                </Text>
              )}

              {!R.isEmpty(divisionOptions) && (
                <FormControl className="division" mb="16px">
                  <InputLabel>{t("physician.division")}</InputLabel>
                  <Select
                    {...register("divisionId")}
                    placeholder={t("physician.divisionPlaceholder")}
                  >
                    {R.map(
                      (division) => (
                        <option key={`dep-${division.id}`} value={division.id}>
                          {division.name}
                        </option>
                      ),
                      divisionOptions
                    )}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </Box>
        )
      }}
    </ConnectForm>
  )
}
