import { Box, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import * as R from "ramda"
import React from "react"
import { useTranslation } from "react-i18next"
import { PracticeLocation } from "./index"

const PracticeHeader = styled(Text)``
PracticeHeader.defaultProps = {
  color: "primary",
  fontWeight: "700",
  fontSize: "16px",
  mt: "10px",
}

export const PracticeLocationList = ({
  secondaryPractices,
  primaryPractice,
  secondaryFacilities,
  secondaryClinics,
  secondaryOffices,
}) => {
  const { t } = useTranslation()

  const renderPrimaryPractice = () => {
    return (
      <>
        <PracticeHeader>
          {t("physician.practiceLocations.primarySite")}
        </PracticeHeader>
        <PracticeLocation
          key={`primary-practice${primaryPractice.label}-${primaryPractice.id}`}
          locationName={primaryPractice.name}
          locationType={primaryPractice.label}
          streetAddress={primaryPractice.address?.streetAddress}
          phoneNumbers={primaryPractice?.phoneNumbers}
          city={primaryPractice.address?.city}
          province={primaryPractice.address?.province}
          postalCode={primaryPractice?.address?.postalCode}
          details={primaryPractice.details}
        />
      </>
    )
  }

  const renderSecondaryPractices = (locations, header) => {
    return (
      <>
        {" "}
        <Text fontWeight="700" mt="8px">
          {header}
        </Text>{" "}
        {R.map(
          (location) => (
            <PracticeLocation
              key={`secondary-practice${location.label}-${location.id}`}
              locationName={location.name}
              locationType={location.label}
              streetAddress={location.address?.streetAddress}
              phoneNumbers={location?.phoneNumbers}
              city={location.address?.city}
              province={location.address?.province}
              postalCode={location.address?.postalCode}
              locationId={location.id}
              details={location.details}
            />
          ),
          locations
        )}
      </>
    )
  }

  return (
    <Box>
      <Box>{primaryPractice && renderPrimaryPractice()}</Box>

      {secondaryPractices.length > 0 && (
        <Box>
          <PracticeHeader>
            {t("physician.practiceLocations.otherSites")}
          </PracticeHeader>

          {secondaryFacilities.length > 0 &&
            renderSecondaryPractices(secondaryFacilities, "Facilities")}

          {secondaryClinics.length > 0 &&
            renderSecondaryPractices(secondaryClinics, "Clinics")}

          {secondaryOffices.length > 0 &&
            renderSecondaryPractices(secondaryOffices, "Offices")}
        </Box>
      )}
    </Box>
  )
}
