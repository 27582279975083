import { Icon } from "@chakra-ui/react"
import React from "react"

export const ChevronLeftIcon = () => (
  <Icon viewBox="0 0 16 16" fill="none">
    <path
      d="M11 15L4 8L11 1"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
