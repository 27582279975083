import { FormLabel } from "@chakra-ui/react"
import styled from "@emotion/styled"

export const InputLabel = styled(FormLabel)``

InputLabel.defaultProps = {
  fontSize: "14px",
  color: "primary",
  letterSpacing: "1px",
  fontWeight: "700",
  textTransform: "uppercase",
}
