import { Box, Divider, Text } from "@chakra-ui/react"
import React from "react"

export const Section = (props) => {
  return (
    <Box bg={props.bgColor}>
      {props.divider && (
        <Box ml="-16px" mr="-16px" mt={4} mb={4}>
          <Divider />
        </Box>
      )}

      {props.title && (
        <>
          <Text
            color="primary"
            fontWeight="700"
            fontSize="18px"
            letterSpacing="1px"
          >
            {props.title}
          </Text>
          <Divider
            border="3px solid"
            borderColor="accentRed"
            mt="8px"
            mb="16px"
          />
        </>
      )}
      {props.children}
    </Box>
  )
}
