import { Icon } from "@chakra-ui/react"
import React from "react"

export const AddressIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8 0.666687C9.5913 0.666687 11.1174 1.29883 12.2426 2.42405C13.3679 3.54926 14 5.07539 14 6.66669C14 9.15069 12.1067 11.7534 8.4 14.5334C8.2846 14.6199 8.14425 14.6667 8 14.6667C7.85575 14.6667 7.7154 14.6199 7.6 14.5334C3.89333 11.7534 2 9.15069 2 6.66669C2 5.07539 2.63214 3.54926 3.75736 2.42405C4.88258 1.29883 6.4087 0.666687 8 0.666687ZM8 4.66669C7.46957 4.66669 6.96086 4.8774 6.58579 5.25247C6.21071 5.62755 6 6.13625 6 6.66669C6 7.19712 6.21071 7.70583 6.58579 8.0809C6.96086 8.45597 7.46957 8.66669 8 8.66669C8.53043 8.66669 9.03914 8.45597 9.41421 8.0809C9.78929 7.70583 10 7.19712 10 6.66669C10 6.13625 9.78929 5.62755 9.41421 5.25247C9.03914 4.8774 8.53043 4.66669 8 4.66669Z"
      fill="currentColor"
    />
  </Icon>
)
