import { Avatar, Square } from "@chakra-ui/react"
import React from "react"
import { UserIcon } from "../../../shared/icons"

export const PhysicianAvatar = (props) => {
  return (
    <Square>
      <Avatar
        showBorder
        border="1px solid #BBBBBB"
        bg="#F2F1F0"
        icon={<UserIcon color="#BBBBBB" />}
        src={props.profileImage}
        size={props.size}
        zIndex="0"
      />
    </Square>
  )
}
