import { Icon } from "@chakra-ui/react"
import React from "react"

export const BlueLogoIcon = (props) => (
  <Icon width="43" height="29" viewBox="0 0 43 29" fill="none" {...props}>
    <path
      d="M8.8 6.5C8.7 7.1 8.7 7.7 8.7 8.4C8.7 10.5 9.1 12.8 9.7 15.2C14 17 17.6 20.2 20.2 24.3C19.9 20.3 18.1 11.2 8.8 6.5ZM33.2 15.2C33.9 12.8 34.2 10.6 34.2 8.4C34.2 7.7 34.1 7.1 34.1 6.5C24.9 11.1 23 20.2 22.7 24.3C25.3 20.3 29 17 33.2 15.2ZM0 16.3C5 27.3 14.6 29.2 19.6 28.6C16 20.3 8.2 15.5 0 16.3ZM23.3 28.5C28.3 29.1 37.9 27.2 42.9 16.2C34.8 15.5 27 20.3 23.3 28.5ZM21.5 0C19.5 2.8 17.9 6.8 17.3 10.3C19.2 12.7 20.6 15.5 21.5 18.5C22.4 15.5 23.8 12.7 25.7 10.3C25.1 6.8 23.5 2.8 21.5 0Z"
      fill="#09347A"
    />
  </Icon>
)
