import { Box } from "@chakra-ui/react"
import * as R from "ramda"
import React from "react"
import { ContactItem } from "./index"

export const SecondaryContactInfo = (props) => {
  const renderContactGroup = (propsArray) => {
    return R.map(
      (contactInfo) => (
        <ContactItem
          key={`${contactInfo.label}-${contactInfo.id}`}
          label={contactInfo.label}
          emailOrNumber={contactInfo.value}
          icon={contactInfo.icon}
          extension={contactInfo.extension}
          smsPreferred={contactInfo.smsPreferred}
        />
      ),
      propsArray
    )
  }

  const renderSecondaryContacts = () => {
    return R.map(
      (contactInfo) => (
        <Box mb="8px" key={`box-${contactInfo.id}`}>
          <ContactItem
            label={contactInfo.label}
            emailOrNumber={contactInfo.value}
            icon={contactInfo.icon}
            extension={contactInfo.extension}
            smsPreferred={contactInfo.smsPreferred}
          />
        </Box>
      ),
      props.secondaryContacts
    )
  }

  return (
    <Box>
      <Box mb="8px">
        {props.adminName && (
          <ContactItem label="Admin" emailOrNumber={props.adminName} />
        )}
        {renderContactGroup(props.adminContacts)}
      </Box>
      <Box mb="8px">
        {props.additionalContact && (
          <ContactItem label="Other" emailOrNumber={props.additionalContact} />
        )}
        {renderContactGroup(props.otherContacts)}
      </Box>

      {renderSecondaryContacts()}
    </Box>
  )
}
