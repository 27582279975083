import {
  Button,
  Center,
  Flex,
  Link as ChakraLink,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { CUIAutoComplete } from "chakra-ui-autocomplete"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMst } from "../setup"
import { FilterIcon } from "./shared/icons"
import { MenuIcon } from "./shared/icons/menu-icon"

export const FilterOptionsModal = (props) => {
  const { t } = useTranslation()

  const { formOptionsStore } = useMst()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [specialtyFilter, setSpecialtyFilter] = useState([])
  const [facilityFilter, setFacilityFilter] = useState([])

  const handleOnOpen = (e) => {
    setSpecialtyFilter(props.specialtyFilter)
    setFacilityFilter(props.facilityFilter)
    onOpen()
  }

  const handleOnClose = () => {
    setSpecialtyFilter([])
    setFacilityFilter([])
    onClose()
  }

  const handleOnApply = (e) => {
    props.setSpecialtyFilter(specialtyFilter)
    props.setFacilityFilter(facilityFilter)
    props.handleSearch({ specialtyFilter, facilityFilter })
    onClose()
  }

  const resetFilters = () => {
    props.clearSearch()
    setSpecialtyFilter([])
    setFacilityFilter([])
  }

  useEffect(() => {
    formOptionsStore.getFormOptions()
  }, [])

  const hamburgerButton = () => {
    return (
      <ChakraLink onClick={onOpen} ml="8px" mr="8px">
        <MenuIcon />
      </ChakraLink>
    )
  }

  return (
    <>
      <ChakraLink
        onClick={handleOnOpen}
        ml="8px"
        mr="8px"
        mt="16px"
        fontSize={16}
        fontWeight="700"
      >
        <Center p="5px">
          <FilterIcon />
          <Text pl="5px">Filter</Text>
        </Center>
      </ChakraLink>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Flex align="center" justify="center">
            <ModalHeader>{t("shared.filters")}</ModalHeader>
            <Spacer />
            <Link mr="24px" textDecoration="none" onClick={resetFilters}>
              {t("shared.resetFilters")}
            </Link>
          </Flex>

          <ModalBody>
            <CUIAutoComplete
              label={"Specialty"}
              placeholder={"Type a specialty to filter"}
              items={(formOptionsStore?.formOptions?.specialties || []).map(
                (item) => {
                  return { value: item.id, label: item.name }
                }
              )}
              selectedItems={specialtyFilter}
              onSelectedItemsChange={(changes) => {
                setSpecialtyFilter(changes.selectedItems)
              }}
            />

            {props.includeFacilities && (
              <CUIAutoComplete
                label={"Facility"}
                placeholder={t("shared.typeFacilityFilter")}
                items={(formOptionsStore?.formOptions?.facilities || []).map(
                  (item) => {
                    return { value: item.id, label: item.name }
                  }
                )}
                selectedItems={facilityFilter}
                onSelectedItemsChange={(changes) => {
                  setFacilityFilter(changes.selectedItems)
                }}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleOnClose}>
              {t("shared.closeButton")}
            </Button>
            <Button mr={3} onClick={handleOnApply}>
              {t("shared.applyFilters")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
