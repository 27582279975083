import { flow, types } from "mobx-state-tree"
import * as R from "ramda"
import { createInfiniteScrollResourceable } from "../compositions/infinite-scroll-resourceable"
import { createSearchable } from "../compositions/searchable"
import { withEnvironment, withRootStore } from "../lib"
import { ClinicModel } from "../models/clinic"

export const ClinicStoreModel = types.compose(
  createInfiniteScrollResourceable({
    collection: {
      name: "clinics",
      apiGetIndexFnName: "getClinics",
    },
    member: {
      selectedResourceName: "selectedClinic",
      apiGetMemberFnName: "getClinic",
    },
  }),
  createSearchable(ClinicModel, "clinics", "searchClinic"),
  types
    .model("ClinicStoreModel")
    .props({
      clinics: types.map(ClinicModel),
      selectedClinic: types.maybeNull(types.reference(ClinicModel)),
    })
    .extend(withRootStore())
    .extend(withEnvironment())
    .actions((self) => ({
      __beforeMergeUpdate(clinicData) {
        // convert physicians in clinicData to models and store them (necessary due to lazy load, we cannot rely on just the id now)
        const { physicians } = clinicData
        if (physicians && !R.isEmpty(physicians)) {
          clinicData["physicians"] = R.map((phys) => {
            self.rootStore.physicianStore.mergeUpdate(phys)
            return phys.id
          }, physicians)
        }
      },
    }))
    .actions((self) => ({
      load: flow(function* () {
        self.getResources()
      }),
    }))
)
