import { Button, Text } from "@chakra-ui/react"
import React from "react"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { Autocomplete } from "."
import { useMst } from "../../../../setup"
import { Section } from "../physician-show"
import { ConnectForm, Facility, Office } from "./index"
import { InputLabel } from "./input-label"

export const LocationsSection = () => {
  const { t } = useTranslation()

  const { formOptionsStore } = useMst()

  const newFacilityObject = () => ({
    id: `new-${uuidv4()}`,
    ownerType: "Facility",
    _destroy: null,
    ownerId: null,
    details: "",
  })

  //ST: deep nested attributes does not work with the array
  //we will write a transform fo ran object to go from shallow nested office to deep nested office
  //retrieval of object form controller will be shallow nested

  const newOfficeObject = () => ({
    id: `new-${uuidv4()}`,
    ownerType: "Office",
    _destroy: null,
    ownerAttributesId: "",
    ownerAttributesName: "",
    ownerAttributesAddressAttributesId: "",
    ownerAttributesAddressAttributesStreetAddress: "",
    ownerAttributesAddressAttributesPostalCode: "",
    ownerAttributesAddressAttributesCity: "",
    ownerAttributesAddressAttributesProvince: "",
    ownerAttributesOfficePhoneNumberAttributesId: "",
    ownerAttributesOfficePhoneNumberAttributesPhoneNumber: "",
    ownerAttributesOfficePhoneNumberAttributesPhoneType: "office",
    ownerAttributesFaxPhoneNumberAttributesId: "",
    ownerAttributesFaxPhoneNumberAttributesPhoneNumber: "",
    ownerAttributesFaxPhoneNumberAttributesPhoneType: "fax",
  })

  return (
    <ConnectForm>
      {({ register, control, watch, setValue, formState: { errors } }) => {
        const {
          fields: facilityFields,
          append: facilityAppend,
          remove: facilityRemove,
        } = useFieldArray({
          control,
          name: "physicianFacilitiesLocationsAttributes",
        })
        const watchFacilities = watch("physicianFacilitiesLocationsAttributes")
        const controlledFacilityFields = facilityFields.map((field, index) => {
          return {
            ...field,
            ...watchFacilities[index],
          }
        })

        const handlePreferredChange = (selectedId) => {
          facilityFields.map((field, index) => {
            setValue(
              `physicianFacilitiesLocationsAttributes[${index}].primaryPractice`,
              field.id == selectedId
            )
          })
        }

        const { fields, append, remove } = useFieldArray({
          control,
          name: "physicianOfficesLocationsAttributes",
        })

        //https://react-hook-form.com/api/usefieldarray look at watch section
        const watchFieldArray = watch("physicianOfficesLocationsAttributes")
        const controlledFields = fields.map((field, index) => {
          return {
            ...field,
            ...watchFieldArray[index],
          }
        })

        return (
          <>
            <Section title="PRACTICE LOCATIONS">
              <Text color="accentRed" fontSize="12px">
                {t("physicianEdit.form.missingDataLocation")}
                <a href={`mailto:pass@providencehealth.bc.ca`}>pass@providencehealth.bc.ca</a>
              </Text>

              <InputLabel>Facilities</InputLabel>

              {controlledFacilityFields.map((field, index) => (
                <Facility
                  fieldIndex={index}
                  register={register}
                  remove={() => facilityRemove(index)}
                  errors={errors}
                  field={field}
                  possibleValues={formOptionsStore.formOptions.facilities}
                  objectIndex={`physicianFacilitiesLocationsAttributes[${index}]`}
                  key={field.id}
                  handlePreferredChange={handlePreferredChange}
                />
              ))}
              <Button
                size="xs"
                onClick={() => {
                  facilityAppend(newFacilityObject())
                }}
              >
                + {t("physician.addFacility")}
              </Button>

              <InputLabel mt="14px">Clinics</InputLabel>
              <Autocomplete
                selectedItems={watch("physicianClinicsLocationsAttributes")}
                setSelectedItems={(newValue) =>
                  setValue("physicianClinicsLocationsAttributes", newValue)
                }
                attributeForValue={"ownerId"}
                possibleValues={formOptionsStore.formOptions.clinics}
                attributeForLabel={"name"}
                placeholder={t("physicianEdit.form.typeClinic")}
                additionalDefaultValues={{ owner_type: "Clinic" }}
              />

              <InputLabel>Offices</InputLabel>

              {controlledFields.map((field, index) => (
                <Office
                  fieldIndex={index}
                  register={register}
                  setValue={setValue}
                  remove={() => remove(index)}
                  errors={errors}
                  field={field}
                  objectIndex={`physicianOfficesLocationsAttributes[${index}]`}
                  key={field.id}
                />
              ))}
              <Button
                size="xs"
                onClick={() => {
                  append(newOfficeObject())
                }}
              >
                + {t("physician.addOffice")}
              </Button>
            </Section>
          </>
        )
      }}
    </ConnectForm>
  )
}
