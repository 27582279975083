import { Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"

export const NoResultsMessage = () => {
  const { t } = useTranslation()
  return (
    <Text fontSize="24px" colot="text" textAlign="center" mt="20px">
      {t("shared.noResults")}
    </Text>
  )
}
