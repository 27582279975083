import { types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const LoadingSpinnerModel = types
  .model("LoadingSpinnerModel")
  .props({
    isVisible: types.maybeNull(types.boolean),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({}))
  .actions((self) => ({
    showSpinner() {
      self.isVisible = true
    },
    hideSpinner() {
      self.isVisible = false
    },
  }))
