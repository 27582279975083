import Basil from "basil.js"
const basil_options = {
  namespace: "providence-fubu",
  storages: ["local", "cookie", "memory"],
}

const basil = new Basil(basil_options)
const STORE_DEBUG = false
export const STORE_KEY = "providence-fubu-store"
export const AUTH_STORE_KEY = `${STORE_KEY}-auth`

const setItem = (key, value, options = {}) => {
  return new Promise((resolve, reject) => {
    basil.set(key, value, options)
    STORE_DEBUG &&
      console.log("setting local cookie/store ", key, " to ", value)
    resolve(value)
  })
}

const getItem = (key) => {
  return new Promise((resolve, reject) => {
    const value = basil.get(key)
    STORE_DEBUG &&
      console.log("getting local cookie/store ", key, " is value ", value)
    resolve(value)
  })
}

const getAccessTokenFromLocalStorage = async () => {
  const cache = (await localCache.getItem(AUTH_STORE_KEY)) as any
  return cache
}

export const localCache = {
  setItem,
  getItem,
  getAccessTokenFromLocalStorage,
}
