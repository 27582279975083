import { types } from "mobx-state-tree"
import * as R from "ramda"
import { createSearchable } from "../compositions/searchable"
import { withEnvironment, withRootStore } from "../lib"
import { ContactModel } from "../models/contact"

export const ContactStoreModel = types.compose(
  createSearchable(ContactModel, "contacts", "searchContact"),
  types
    .model("ContactStoreModel")
    .props({
      contacts: types.map(ContactModel),
    })
    .extend(withRootStore())
    .extend(withEnvironment())
    .actions((self) => ({
      updateContactData(contactData) {
        const existingContact = self.contacts.get(contactData.id) || {}
        self.contacts.put(R.mergeDeepLeft(contactData, existingContact))
      },
      __afterSuccessfulSearch(contact) {
        // since things are lazyloaded, if the directorySection doesn't exist - grab it
        const { facilityStore } = self.rootStore
        const facility = facilityStore.facilities.get(contact.facilityId)
        facility.getDirectorySectionIfNeeded(contact.directorySectionId)
      },
    }))
)
