import { ApisauceInstance, create, Monitor } from "apisauce"
import jsonToFormData from "json-form-data"
import { camelizeResponse, decamelizeRequest } from "../../utils"

export class Api {
  client: ApisauceInstance

  constructor() {
    this.client = create({
      baseURL: "/api",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      timeout: 30000,
    })

    this.client.addResponseTransform((response) => {
      response.data = camelizeResponse(response.data)
    })

    this.client.addRequestTransform((request) => {
      if (request?.data?.["profileImage"]) {
        let originalData = request.data
        request.headers = {
          ...request.headers,
          "Content-Type": "multipart/form-data",
        }
        request.params = decamelizeRequest(request.params)

        //TODO: look at moving the decamelization logic into decamelize request
        let decamelizedData = decamelizeRequest(request.data)
        decamelizedData["profile_image"] = originalData["profileImage"] //necessary to not lose the values via decamelization

        //https://github.com/hyperatom/json-form-data#readme
        //the default mapping needs to be overriden to not replace booleans with 1's
        request.data = jsonToFormData(decamelizedData, {
          initialFormData: new FormData(),
          showLeafArrayIndexes: false, //do not reconfigure array for department roles
          includeNullValues: false,
          mapping: function (value) {
            // if (typeof value === "boolean") {
            //   return +value ? "1" : "0"
            // }
            return value
          },
        })
      } else {
        request.params = decamelizeRequest(request.params)
        request.data = decamelizeRequest(request.data)
      }
    })
  }

  addMonitor(monitor: Monitor) {
    this.client.addMonitor(monitor)
  }

  setJwt(bearerJwt: string) {
    this.client.setHeader("Authorization", bearerJwt)
  }

  async login(email, password) {
    return this.client.post("/login", { physician: { email, password } })
  }

  async validateToken() {
    return this.client.get("/validate_token")
  }

  async logout() {
    return this.client.delete("/logout")
  }

  async getPhysicians(page = 1) {
    return this.client.get(`/physicians?page=${page}`)
  }

  async getPhysician(id) {
    return this.client.get(`/physicians/${id}`)
  }

  async updatePhysician(physicianId, values) {
    return this.client.put(`/physicians/${physicianId}`, values)
  }

  async getFormOptions() {
    return this.client.get("/form_options")
  }

  async getProfile() {
    return this.client.get("/profile")
  }

  async acceptTermsAndConditions(id) {
    return this.client.put(`physicians/${id}/accept_terms`)
  }

  searchPhysician = async (term, specialties_ids, facility_ids) => {
    return this.client.get(
      `/physicians/search?query=${term}&specialties_ids=${specialties_ids}&facility_ids=${facility_ids}`
    )
  }

  searchClinic = async (term, specialties_ids) => {
    return this.client.get(
      `/clinics/search?query=${term}&specialties_ids=${specialties_ids}`
    )
  }

  searchContact = async (term, facilityId) => {
    return this.client.get(
      `/contacts/search?query=${term}&facilityId=${facilityId}`
    )
  }

  async getClinics(page = 1) {
    return this.client.get(`/clinics?page=${page}`)
  }

  async getClinic(id) {
    return this.client.get(`/clinics/${id}`)
  }

  async getFavorites() {
    return this.client.get("/favorites")
  }

  async getFacilities() {
    return this.client.get("/facilities")
  }

  async getFacility(id) {
    return this.client.get(`/facilities/${id}`)
  }

  async getDirectorySections(facilityId, page = 1) {
    return this.client.get(
      `/facilities/${facilityId}/directory_sections?page=${page}`
    )
  }

  async getDirectorySection(directorySectionId) {
    return this.client.get(`/directory_sections/${directorySectionId}`)
  }

  async resetPassword(email) {
    return this.client.post("/password", { email })
  }

  async createFavorite(favoritableType, favoritableId, local = false) {
    if (local) {
      // if local, generate a data structure that matches the model so that it can be persisted in localstorage
      return {
        ok: true,
        data: {
          data: {
            // use favoritableId as the id in local as this should be unique for contacts and gives us an easy way to remove them when we need
            // this works only because we don't need to favourite anything other than contacts
            id: favoritableId,
            favoritableId,
            favoritableType,
            physicianId: null, // does not belong to any physician
          },
        },
      }
    } else {
      return this.client.post("/favorites", {
        favorite: {
          favoritable_type: favoritableType,
          favoritable_id: favoritableId,
        },
      })
    }
  }

  async removeFavorite(favoritableType, favoritableId, local = false) {
    if (local) {
      return {
        ok: true,
        data: {
          id: favoritableId,
          favoritableId,
          favoritableType,
        },
      }
    } else {
      return this.client.delete("/favorites", {
        favoritable_type: favoritableType,
        favoritable_id: favoritableId,
      })
    }
  }

  async getContacts() {
    return this.client.get("/contacts")
  }

  async accountUpdate(values) {
    return this.client.put("/physicians/account_update", values)
  }
}
