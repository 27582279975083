import { Icon } from "@chakra-ui/react"
import React from "react"

export const MedicalIcon = (props) => (
  <Icon viewBox="0 0 100 100" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M70.6,42.3H57.7V29.4c0-1.5-1.2-2.7-2.7-2.7H44.9c-1.5,0-2.7,1.2-2.7,2.7v12.9H29.4c-1.5,0-2.7,1.2-2.7,2.7
		v10.1c0,1.5,1.2,2.7,2.7,2.7h12.9v12.9c0,1.5,1.2,2.7,2.7,2.7h10.1c1.5,0,2.7-1.2,2.7-2.7v-13h12.9c1.5,0,2.7-1.2,2.7-2.7V44.9
		C73.3,43.4,72.1,42.3,70.6,42.3z"
    />
    <path
      fill="currentColor"
      d="M50,10c-22.1,0-40,17.9-40,40s17.9,40,40,40s40-17.9,40-40S72.1,10,50,10z M73.6,73.6
		c-6.3,6.3-14.7,9.8-23.6,9.8s-17.3-3.5-23.6-9.8S16.6,58.9,16.6,50s3.5-17.3,9.8-23.6s14.7-9.8,23.6-9.8s17.3,3.5,23.6,9.8
		c6.3,6.3,9.8,14.7,9.8,23.6S79.9,67.3,73.6,73.6z"
    />
  </Icon>
)
