import { types } from "mobx-state-tree"
import * as R from "ramda"
import { createFavoritable } from "../compositions/favoritable"
import { withRootStore } from "../lib"
import { ISpecialties } from "../types"
import { IAddressModel } from "./address"
import { IPhoneModel } from "./phone"
import { PhysicianModel } from "./physician"

export const ClinicModel = types.compose(
  createFavoritable("favoriteClinics"),
  types
    .model("ClinicModel")
    .props({
      id: types.identifierNumber,
      name: types.optional(types.string, ""),
      phoneNumbers: types.array(types.frozen<IPhoneModel>()),
      specialties: types.maybeNull(types.frozen<ISpecialties>()),
      physicians: types.array(types.reference(PhysicianModel)),
      address: types.maybeNull(types.frozen<IAddressModel>()),
    })
    .extend(withRootStore())
    .views((self) => ({
      get twoSpecialties() {
        return R.slice(0, 2, self.specialties)
      },

      get shouldTruncateSpecialties() {
        return R.length(self.specialties) > 2
      },

      get officePhone() {
        return R.find(R.propEq("phoneType", "office"))(self.phoneNumbers)
      },
    }))
    .actions((self) => ({}))
)
