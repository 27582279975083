import { types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { ContactModel } from "./contact"

export const DirectorySectionModel = types
  .model("DirectorySectionModel")
  .props({
    id: types.identifierNumber,
    name: types.optional(types.string, ""),
    contacts: types.array(types.reference(ContactModel)),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get searchResultContacts() {
      const {
        contactStore: { searchResults },
      } = self.rootStore
      return R.filter((contact) => {
        return R.contains(contact.id, R.pluck("id", searchResults))
      }, self.contacts)
    },
  }))
  .actions((self) => ({}))
