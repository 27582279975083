import { Checkbox, FormControl, Input, Text, Textarea } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Section } from "../physician-show"
import { DepartmentRoles } from "./DepartmentRoles"
import { ConnectForm, DepartmentAndDivision, SpecialtiesSection } from "./index"
import { InputLabel } from "./input-label"

export const ProfileSection = () => {
  const { t } = useTranslation()

  return (
    <ConnectForm>
      {({ register, watch, setValue, formState: { errors } }) => {
        return (
          <>
            <input
              type="hidden"
              defaultValue={true}
              {...register("confirming")}
            />
            <FormControl className="first-name" isRequired>
              <InputLabel>{t("physician.firstNameLabel")}</InputLabel>
              <Input
                color="text"
                mb={3}
                fontSize="14px"
                {...register("firstName", {
                  required: "This is a required field",
                })}
              />
              {errors.firstName && (
                <Text color="red" fontSize="14px">
                  {errors.firstName.message}
                </Text>
              )}
            </FormControl>
            <FormControl className="last-name" isRequired>
              <InputLabel>{t("physician.lastNameLabel")}</InputLabel>
              <Input
                color="text"
                mb={3}
                fontSize="14px"
                {...register("lastName", {
                  required: "This is a required field",
                })}
              />
              {errors.lastName && (
                <Text color="red" fontSize="14px">
                  {errors.lastName.message}
                </Text>
              )}
            </FormControl>

            <FormControl className="title">
              <Checkbox {...register("physicianOrSurgeon")}>
                <Text color="text" fontSize="16px">
                  {" "}
                  {t("physician.physicianOrSurgeon")}
                </Text>
              </Checkbox>
            </FormControl>

            <Section divider={true}>
              <FormControl className="mspid">
                <InputLabel>{t("physician.mspidLabel")}</InputLabel>
                <Input
                  color="text"
                  mb={3}
                  fontSize="16px"
                  {...register("mspid")}
                />
              </FormControl>

              <SpecialtiesSection />

              <DepartmentAndDivision />

              <DepartmentRoles
                roles={watch("departmentRole") || []}
                setRoles={(newValue) => setValue("departmentRole", newValue)}
              />

              <FormControl className="case-types">
                <InputLabel>{t("physician.caseTypesHeader")}</InputLabel>
                <Textarea
                  resize="none"
                  borderRadius={0}
                  variant="outline"
                  fontSize="14px"
                  color="text"
                  {...register("caseType")}
                  placeholder={t("physician.caseTypesPlaceholder")}
                />
              </FormControl>
            </Section>
          </>
        )
      }}
    </ConnectForm>
  )
}
