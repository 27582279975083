import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Slide,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React from "react"
import { useMst } from "../../../setup/root"

export const FlashMessage = observer(() => {
  const { uiStore } = useMst()
  const { isVisible, status, title, description, textColor } =
    uiStore.flashMessage

  return (
    <Box position="fixed" zIndex="99">
      <Slide direction={"top"} in={isVisible}>
        <Alert color={textColor} status={status}>
          <AlertIcon />
          {title && <AlertTitle>{title}</AlertTitle>}
          {description && <AlertDescription>{description}</AlertDescription>}
        </Alert>
      </Slide>
    </Box>
  )
})
