import { types } from "mobx-state-tree"

export const EmailModel = types
  .model("EmailModel")
  .props({
    id: types.identifierNumber,
    email: types.optional(types.string, ""),
    emailType: types.optional(types.string, ""),
    preferred: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get label() {
      return "Email"
    },
    get value() {
      return self.email
    },
    get icon() {
      return "email"
    },
  }))
  .actions((self) => ({}))
