import {
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react"
import styled from "@emotion/styled"
import React from "react"
import { useTranslation } from "react-i18next"
import { InputLabel } from "./input-label"
import { StyledBox } from "./PhoneNumber"

export const Facility = (props) => {
  const { t } = useTranslation()
  const {
    field,
    objectIndex,
    register,
    remove,
    possibleValues,
    errors,
    handlePreferredChange,
  } = props
  return (
    <StyledBox
      bg="gray.100"
      mt={3}
      p={5}
      backgroundColor="background"
      borderRadius={4}
      selected={field.primaryPractice}
    >
      <StyledFieldset disabled={field._destroy == "1"}>
        <input type="hidden" {...register(`${objectIndex}.id`)} />
        <input type="hidden" {...register(`${objectIndex}.ownerType`)} />
        <Flex justifyContent={"flex-end"}>
          {handlePreferredChange && (
            <Button
              size="xs"
              variant="secondary"
              disabled={props.field.primaryPractice}
              onClick={(e) => {
                handlePreferredChange(field.id)
              }}
            >
              Set as Primary
            </Button>
          )}
        </Flex>
        <FormControl className="site" isRequired>
          <InputLabel>{t("physician.site")}</InputLabel>

          <Select
            {...register(`${objectIndex}.ownerId`, {
              required: "This is a required field",
            })}
            placeholder={t("physician.facilitySelect")}
            backgroundColor="white"
          >
            {possibleValues.map((facility) => (
              <option key={`fac-${facility.id}`} value={facility.id}>
                {facility.name}
              </option>
            ))}
          </Select>
          {props.errors?.physicianFacilitiesLocationsAttributes &&
            props.errors?.physicianFacilitiesLocationsAttributes?.[
              `${props.fieldIndex}`
            ]?.ownerId?.message && (
              <Text color="red" fontSize="14px">
                {
                  props.errors?.physicianFacilitiesLocationsAttributes?.[
                    `${props.fieldIndex}`
                  ]?.ownerId?.message
                }
              </Text>
            )}
        </FormControl>
        <FormControl className="facility-details">
          <InputLabel>{t("physician.facilityDetails")}</InputLabel>
          <Input
            color="text"
            mb={3}
            fontSize="14px"
            backgroundColor="white"
            {...register(`${objectIndex}.details`)}
            placeholder={t("physicianEdit.form.facilityDetailsHint")}
          />
        </FormControl>
      </StyledFieldset>
      {!field.primaryPractice && (
        <Flex>
          {typeof field.id == "string" && field.id.startsWith("new-") ? (
            <Button size="xs" onClick={remove} disabled={field.primaryPractice}>
              x {t("physician.removeButton")}
            </Button>
          ) : (
            <FormControl mb={2}>
              <InputLabel>{t("physician.removeSavedRecord")}</InputLabel>
              <Switch {...register(`${objectIndex}._destroy`)} value={"1"} />
            </FormControl>
          )}
        </Flex>
      )}
    </StyledBox>
  )
}

const StyledFieldset = styled.fieldset`
  ${(props) => (props.disabled ? "border: 2px solid red" : "border: 0px")}
`
