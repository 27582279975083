import React from "react"
import {
  CellPhoneIcon,
  EmailIcon,
  FaxIcon,
  OfficePhoneIcon,
  PagerIcon,
} from "./index"

export const ContactInfoIcon = (props) => {
  switch (props.icon) {
    case "cell":
      return <CellPhoneIcon color={props.color} />
      break
    case "other":
    case "admin":
    case "office":
    case "phone":
      return <OfficePhoneIcon color={props.color} />
      break
    case "email":
      return <EmailIcon color={props.color} />
      break
    case "pager":
      return <PagerIcon color={props.color} />
      break
    case "fax":
      return <FaxIcon color={props.color} />
    default:
      return null
  }
}
