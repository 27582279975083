import { Instance, types } from "mobx-state-tree"
import { FlashMessageModel } from "../models"

export const UIStoreModel = types
  .model("UIStoreModel")
  .props({
    flashMessage: types.optional(FlashMessageModel, {}),
    loadingSpinner: types.optional(types.boolean, false),
  })
  .views((self) => ({}))
  .actions((self) => ({
    showSpinner() {
      self.loadingSpinner = true
    },
    hideSpinner() {
      self.loadingSpinner = false
    },
  }))

export interface IUIStore extends Instance<typeof UIStoreModel> {}
