import { createBrowserHistory } from "history"
import { RouterModel, syncHistoryWithStore } from "mst-react-router"
import apisaucePlugin from "reactotron-apisauce"
import { mst } from "reactotron-mst"
import reactotron from "reactotron-react-js"
import { Environment } from "../models"
import { Api } from "../services/api/api"

declare global {
  interface Console {
    tron: any
  }
}

type TReactotron = typeof reactotron

function setupReactotron() {
  if (process.env.NODE_ENV !== "production") {
    reactotron
      .configure({ name: "PHC Directory" })
      .use(apisaucePlugin())
      .use(
        mst({
          filter: (x) => !x.name.endsWith("@APPLY_SNAPSHOT"),
        })
      )
      .connect()

    console.tron = reactotron as TReactotron
  }
}

export function setupEnvironment() {
  const environment = new Environment()
  const browserHistory = createBrowserHistory()
  const routerModel = RouterModel.create()
  const history = syncHistoryWithStore(browserHistory, routerModel)
  environment.router = routerModel
  environment.routerHistory = history
  environment.api = new Api()

  setupReactotron()

  return environment
}
