import React from "react"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Section } from "../physician-show"
import { ConnectForm, Email } from "./index"

export const Emails = () => {
  const { t } = useTranslation()
  return (
    <ConnectForm>
      {({ register, setValue, watch, control, formState: { errors } }) => {
        const { fields } = useFieldArray({
          control,
          name: "physicianEmailsAttributes",
        })

        const watchFieldArray = watch("physicianEmailsAttributes")
        const controlledFields = fields.map((field, index) => {
          return {
            ...field,
            ...watchFieldArray[index],
          }
        })

        const handlePreferredChange = (preferredType) => {
          fields.map((field, index) => {
            setValue(
              `physicianEmailsAttributes[${index}].preferred`,
              field.emailType == preferredType
            )
          })
        }
        const getPreferredType = controlledFields.find((f) => f?.preferred)
          ?.emailType

        return (
          <Section mt={5} title="Emails">
            {controlledFields.map((field, index) => (
              <Email
                register={register}
                errors={errors}
                field={field}
                fieldName={"physicianEmailsAttributes"}
                fieldIndex={index}
                key={field.id}
                handlePreferredChange={handlePreferredChange}
              />
            ))}
          </Section>
        )
      }}
    </ConnectForm>
  )
}
