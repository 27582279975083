import { Button, Container, FormControl, Input, Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useMst } from "../../../../setup"
import { resetToHomeScreen } from "../../../../utils/utils"
import { InputLabel } from "../physician-edit/input-label"

export const UpdateAccount = () => {
  let history = useHistory()
  const { t } = useTranslation()
  const { handleSubmit, register, formState, watch, setValue } = useForm()
  const { physicianStore, userStore } = useMst()
  const { profile } = userStore

  useEffect(() => {
    setValue("email", profile.email)
  })

  const onSubmit = (values) => {
    physicianStore.accountUpdate(values).then((success) => {
      if (success) {
        resetToHomeScreen(history)
      }
    })
  }

  return (
    <Container>
      <Text
        color="text"
        fontWeight="700"
        fontSize="24px"
        mt="10px"
        align="center"
      >
        {t("physician.updateAccount.title")}
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt={5}>
          <InputLabel>
            {t("physician.updateAccount.form.emailLabel")}
          </InputLabel>
          <Text color="textMuted" fontSize="12px">
            {t("physician.updateAccount.form.emailInfo")}
          </Text>
          <Input mb={6} {...register("email")} />
        </FormControl>
        <FormControl mt={0}>
          <InputLabel>
            {t("physician.updateAccount.form.currentPasswordLabel")}
          </InputLabel>
          <Text color="textMuted" fontSize="12px">
            {t("physician.updateAccount.form.currentPasswordInfo")}
          </Text>
          <Input
            label="Current Password"
            type="password"
            mb={6}
            {...register("currentPassword")}
          />
        </FormControl>
        <FormControl mt={5}>
          <InputLabel>New Password</InputLabel>
          <Text color="textMuted" fontSize="12px">
            {t("physician.updateAccount.form.passwordComplexityInfo")}
          </Text>
          <Input type="password" mb={6} {...register("password")} />
        </FormControl>
        <FormControl mt={5}>
          <InputLabel>Password Confirmation</InputLabel>
          <Input type="password" mb={6} {...register("passwordConfirmation")} />
          <Button variant="outline" type="submit" w="100%">
            {t("physician.updateAccount.form.submitLabel")}
          </Button>
        </FormControl>
      </form>
    </Container>
  )
}
