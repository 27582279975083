import { types } from "mobx-state-tree"
import * as R from "ramda"
import { createFavoritable } from "../compositions/favoritable"
import { withRootStore } from "../lib"
import {
  IDepartment,
  IDivision,
  IPhysicianLocations,
  IPhysicianSpecialties,
  ISpecialties,
} from "../types"
import { EmailModel } from "./email"
import { LocationModel } from "./location"
import { PhoneModel } from "./phone"

export const PhysicianModel = types.compose(
  createFavoritable("favoritePhysicians"),
  types
    .model("PhysicianModel")
    .props({
      id: types.identifierNumber,
      firstName: types.optional(types.string, ""),
      lastName: types.optional(types.string, ""),
      email: types.optional(types.string, ""),
      profileImage: types.maybeNull(types.string),
      mspid: types.optional(types.string, ""),
      aboutMe: types.optional(types.string, ""),
      clinicalAreasOfInterest: types.optional(types.string, ""),
      researchAreas: types.optional(types.string, ""),
      personalAreasOfInterest: types.optional(types.string, ""),
      departmentRole: types.array(types.string),
      caseType: types.optional(types.string, ""),
      physicianOrSurgeon: types.maybeNull(types.boolean),
      adminName: types.optional(types.string, ""),
      additionalContactDescription: types.optional(types.string, ""),
      department: types.maybeNull(types.frozen<IDepartment>()),
      division: types.maybeNull(types.frozen<IDivision>()),
      clinics: types.array(LocationModel),
      facilities: types.array(LocationModel),
      offices: types.array(LocationModel),
      specialties: types.maybeNull(types.frozen<ISpecialties>()),
      physicianEmails: types.array(EmailModel),
      phoneNumbers: types.array(PhoneModel),
      aasmState: types.optional(types.string, ""),
      departmentId: types.maybeNull(types.number),
      divisionId: types.maybeNull(types.number),
      phoneNumbersAttributes: types.array(PhoneModel),
      physicianEmailsAttributes: types.array(EmailModel),
      physicianSpecialtiesAttributes: types.maybeNull(
        types.frozen<IPhysicianSpecialties>()
      ),
      physicianFacilitiesLocationsAttributes: types.maybeNull(
        types.frozen<IPhysicianLocations>()
      ),
      physicianClinicsLocationsAttributes: types.maybeNull(
        types.frozen<IPhysicianLocations>()
      ),
      //offices is a much deeper nesting
      physicianOfficesLocationsAttributes: types.maybeNull(types.frozen()),
    })
    .extend(withRootStore())
    .views((self) => ({
      get preferredEmail() {
        return R.find(R.propEq("preferred", true))(self.physicianEmails)
      },
      get preferredPhone() {
        return R.find(R.propEq("preferred", true))(self.phoneNumbers)
      },
      get locations() {
        const clinicsAndFacilities = R.concat(self.clinics, self.facilities)
        return R.concat(clinicsAndFacilities, self.offices)
      },
      get fullName() {
        return `${self.firstName} ${self.lastName}`
      },
      get allContacts() {
        return R.concat(self.phoneNumbers, self.physicianEmails)
      },
    }))
    .views((self) => ({
      get primaryPractice() {
        return (
          R.find(R.propEq("primaryPractice", true))(self.facilities) ||
          R.head(self.facilities)
        )
      },
      get secondaryPractices() {
        const secondary = (n) => n.primaryPractice != true //to account for null cases
        return R.filter(secondary, self.locations)
      },
      get formatTitle() {
        return self.physicianOrSurgeon ? `Dr. ${self.fullName}` : self.fullName
      },
      get adminContacts() {
        const admin = (n) =>
          (n.emailType == "admin" || n.phoneType == "admin") && n.value
        return R.filter(admin, self.allContacts) // all admin phones and email
      },
      get otherContacts() {
        const other = (n) =>
          (n.emailType == "other" || n.phone_type == "other") && n.value
        return R.filter(other, self.allContacts) // all other phones and emails
      },
    }))
    .views((self) => ({
      get contactsExceptAdminAndOther() {
        const adminAndOther = (n) =>
          n.emailType !== "admin" &&
          n.phoneType !== "admin" &&
          n.emailType !== "other" &&
          n.phone_type !== "other" &&
          n.value
        return R.filter(adminAndOther, self.allContacts)
      },
      get secondaryClinics() {
        const clinics = (n) => n.type == "clinic"
        return R.filter(clinics, self.secondaryPractices)
      },
      get secondaryFacilities() {
        const facilities = (n) => n.type == "facility"
        return R.filter(facilities, self.secondaryPractices)
      },
      get secondaryOffices() {
        const offices = (n) => n.type == "office"
        return R.filter(offices, self.secondaryPractices)
      },
    }))
    .actions((self) => ({}))

  // get secondaryPractices() {
  //   const secondary = (n) => n.primaryPractice != true //to account for null cases
  //   return R.filter(secondary, self.locations)
  // },
)
