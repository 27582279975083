import { Box, Center, Container, Select, Spinner } from "@chakra-ui/react"
import { values } from "mobx"
import { observer } from "mobx-react-lite"
import * as R from "ramda"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useMst } from "../../../setup"
import { DirectorySection } from "./directory-section"

export const DirectoryShow = observer(({ displayAll, clearSearch }) => {
  const { facilityStore, sessionStore } = useMst()
  const { t } = useTranslation()
  const { selectedFacility: f } = facilityStore

  useEffect(() => {
    clearSearch()
  }, [])

  const handleChange = (id) => {
    facilityStore.setSelectedFacility(id)
  }

  const renderDirectorySectionView = () => {
    if (displayAll) {
      const {
        directorySections,
        getDirectorySections,
        hasMoreDirectorySectionPage,
      } = f
      return (
        <InfiniteScroll
          dataLength={directorySections.length}
          next={getDirectorySections}
          hasMore={hasMoreDirectorySectionPage}
          loader={
            <Center>
              <Spinner position="fixed" size="md" />
            </Center>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{t("home.infiniteScrollEndMessage")}</b>
            </p>
          }
        >
          {renderDirectorySections()}
        </InfiniteScroll>
      )
    } else {
      return renderDirectorySections()
    }
  }

  const renderDirectorySections = () => {
    return R.map((section) => {
      const contacts = displayAll
        ? section.contacts
        : section.searchResultContacts
      return (
        !R.isEmpty(contacts) && (
          <DirectorySection
            key={`directory-section${section.id}-${section.name}`}
            directoryName={section.name}
            contacts={contacts}
            facilityName={f.name}
          />
        )
      )
    }, f.directorySections)
  }

  const paddingTop = () => {
    if (sessionStore.loggedIn) {
      return "110px"
    } else {
      return "63px"
    }
  }

  const topDistance = () => {
    if (sessionStore.loggedIn) {
      return "137px"
    } else {
      return "92px"
    }
  }

  return (
    <>
      <Select
        zIndex="2"
        position="fixed"
        pb={8}
        pt={2}
        top={topDistance()}
        placeholder={t("physician.facilitySelect")}
        onChange={(e) => handleChange(e.target.value)}
        backgroundColor="white"
        value={f ? f.id : ""}
      >
        {R.map(
          (facility) => (
            <option key={`fac-${facility.id}`} value={facility.id}>
              {facility.name}
            </option>
          ),
          values(facilityStore.facilities)
        )}
      </Select>
      <Container
        bg="background"
        minHeight="100vh"
        pr={0}
        pl={0}
        pt={paddingTop()}
        mb="8px"
      >
        <Box mt="48px"> {f && renderDirectorySectionView()}</Box>
      </Container>
    </>
  )
})
