import { Icon } from "@chakra-ui/react"
import React from "react"

export const PlusIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8 8H1M8 15V8V15ZM8 8V1V8ZM8 8H15H8Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
