import { Icon } from "@chakra-ui/react"
import React from "react"

export const CellPhoneIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98954 0.489543C4.26439 0.214692 4.65988 0 5.1 0H10.4333C10.8735 0 11.2689 0.214692 11.5438 0.489543C11.8186 0.764394 12.0333 1.15988 12.0333 1.6V14.4C12.0333 14.8401 11.8186 15.2356 11.5438 15.5105C11.2689 15.7853 10.8735 16 10.4333 16H5.1C4.65988 16 4.26439 15.7853 3.98954 15.5105C3.71469 15.2356 3.5 14.8401 3.5 14.4V1.6C3.5 1.15988 3.71469 0.764394 3.98954 0.489543ZM4.56667 2.66667V13.3333H10.9667V2.66667H4.56667ZM10.9667 1.6H4.56667C4.56667 1.50679 4.61864 1.36894 4.74379 1.24379C4.86894 1.11864 5.00679 1.06667 5.1 1.06667H10.4333C10.5265 1.06667 10.6644 1.11864 10.7895 1.24379C10.9147 1.36894 10.9667 1.50679 10.9667 1.6ZM10.9667 14.4H4.56667C4.56667 14.4932 4.61864 14.6311 4.74379 14.7562C4.86894 14.8814 5.00679 14.9333 5.1 14.9333H10.4333C10.5265 14.9333 10.6644 14.8814 10.7895 14.7562C10.9147 14.6311 10.9667 14.4932 10.9667 14.4Z"
      fill="currentColor"
    />
  </Icon>
)
