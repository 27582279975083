import {
  Button,
  Center,
  Container,
  FormControl,
  Input,
  Link,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import LogInBackground from "../../../images/bg-login.png"
import { CONSTANTS } from "../../../services/constants"
import { useMst } from "../../../setup"
import { BlueLogoIcon } from "../../shared/icons"

export const LoginForm = () => {
  const { t } = useTranslation()
  const { handleSubmit, errors, register, formState, watch } = useForm()
  const { sessionStore, uiStore } = useMst()
  let history = useHistory()

  const onSubmit = (values) => {
    sessionStore.login(values["email"], values["password"]).then((success) => {
      if (success) {
        history.push(`/physicians`)
      }
    })
  }

  const handleResetPassword = () => {
    sessionStore.resetPassword(watch("email"))
  }

  return (
    <>
      <Container>
        <Center mt={5} mb={15}>
          <BlueLogoIcon boxSize="5em" />
        </Center>
        <Center>
          <Text fontSize="24px" color="text" mb="24px" fontWeight="700">
            {t("login.slogan")}
          </Text>
        </Center>

        <Center>
          <Text fontSize="24px" color="text" mb="24px" fontWeight="700">
            {t("login.appName")}
          </Text>
        </Center>

        <Center>
          <Text fontSize="14px" color="accentRed" mb="16px" fontWeight="700">
            {t("login.toContinue")}
          </Text>
        </Center>
      </Container>

      <Container
        bgPosition="bottom left"
        bgImage={`url('${LogInBackground}')`}
        p="24px"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Input
              mb={6}
              {...register("email")}
              placeholder="Email"
              bgColor="white"
            />
            <Input
              mb={6}
              {...register("password")}
              type="password"
              placeholder="Password"
              bgColor="white"
            />
            {}
            <Button
              variant="ghost"
              type="submit"
              isLoading={formState.isSubmitting}
              w="100%"
              color="white"
              border="1px solid white"
            >
              Sign In
            </Button>
          </FormControl>
        </form>
        <Center>
          <Text color="white" fontSize={14} mt="16px">
            By signing in you agree to the{" "}
            <Link
              color="white"
              textDecoration="underline"
              href={`${CONSTANTS.TERMS_AND_CONDITIONS_LINK}`}
              target="_blank"
              isExternal
            >
              Terms and Conditions.
            </Link>
          </Text>
        </Center>
        <Center>
          <Link
            textDecoration="underline"
            color="white"
            onClick={handleResetPassword}
            fontSize={14}
            mt="16px"
            fontWeight="bold"
          >
            {t("login.forgotPassword")}
          </Link>
        </Center>
        <a href={`mailto:pass@providencehealth.bc.ca`}>
          <Center>
            <Text color="white" fontSize={14} mt="16px" textAlign="center">
              {t("login.setUpAccount")}
            </Text>
          </Center>
        </a>
        <Center>
          <Link
            textDecoration="underline"
            color="white"
            href={`${CONSTANTS.NEED_HELP_LINK}`}
            isExternal
            fontSize={14}
            mt="16px"
            fontWeight="bold"
          >
            {t("login.needHelp")}
          </Link>
        </Center>
      </Container>
    </>
  )
}
