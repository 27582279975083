import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react"
import styled from "@emotion/styled"
import * as R from "ramda"
import React from "react"
import { useTranslation } from "react-i18next"
import { ContactInfoIcon } from "../../../shared/icons/contact-info-icon"
import { InputLabel } from "./input-label"

export const PhoneNumber = (props) => {
  const { t } = useTranslation()

  //if there is a field index assume its an array of items and the field name is the name of the array
  const fieldName = !R.isNil(props.fieldIndex)
    ? `${props.fieldName}[${props.fieldIndex}]`
    : props.fieldName
  const errorsAttribute = !R.isNil(props.fieldIndex)
    ? props.errors?.[props.fieldName]?.[props.fieldIndex]
    : props.errors?.[props.fieldName]

  return (
    <StyledBox mb="24px" selected={props.field.preferred}>
      <input type="hidden" {...props.register(`${fieldName}.id`)} />
      <input type="hidden" {...props.register(`${fieldName}.preferred`)} />
      <FormControl className="phone">
        <Flex justifyContent={"space-between"}>
          <InputLabel>{props.field.phoneType}</InputLabel>
          <Text color="textMuted" fontSize="12px">
            {t("physicianEdit.form.phoneHint")}
          </Text>
          <FormLabel>
            {props.handlePreferredChange && (
              <Button
                size="xs"
                variant="secondary"
                disabled={props.field.preferred}
                onClick={(e) => {
                  props.handlePreferredChange(props.field.phoneType)
                }}
              >
                {t("physicianEdit.form.preferredCheckbox")}
              </Button>
            )}
          </FormLabel>
        </Flex>
        <Flex>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={
                <ContactInfoIcon icon={props.field.phoneType} color="text" />
              }
            />
            <Input
              backgroundColor="white"
              color="text"
              fontSize="14px"
              {...props.register(`${fieldName}.phoneNumber`, {
                minLength: {
                  value: 5,
                  message: "Phone numbers must have at least 5 digits",
                },
                pattern: {
                  value: /^[0-9+\-() ]+$/,
                  message: "Please only insert numbers, -, + and ()",
                },
              })}
            />
          </InputGroup>

          <Input
            w="60%"
            placeholder="Ext."
            backgroundColor="white"
            color="text"
            fontSize="14px"
            ml={1}
            {...props.register(`${fieldName}.extension`, {
              pattern: {
                value: /^[0-9]+$/,
                message: "Please insert numbers only in the extension",
              },
            })}
          />
        </Flex>

        <Checkbox {...props.register(`${fieldName}.smsPreferred`)} mt={2}>
          <Text fontSize="13px" color="textMuted">
            {" "}
            {t("physician.smsPreferred")}
          </Text>
        </Checkbox>
      </FormControl>
      {errorsAttribute?.extension?.message && (
        <Text color="red" fontSize="14px">
          {errorsAttribute?.extension?.message}
        </Text>
      )}
      {errorsAttribute?.message && (
        <Text color="red" fontSize="14px">
          {errorsAttribute?.phoneNumber?.message}
        </Text>
      )}
    </StyledBox>
  )
}

export const StyledBox = styled(Box)`
  ${(props) => (props.selected ? `border: 2px solid blue` : "border: 0px")}
`
