import { Box, Center, Spinner } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React from "react"
import { useMst } from "../../../setup/root"

export const LoadingSpinnerOverlay = observer(() => {
  const { sessionStore, userStore, physicianStore } = useMst()

  return (
    <Box
      visibility={
        sessionStore.loading || userStore.loading || physicianStore.loading
          ? "visible"
          : "hidden"
      }
      position="fixed"
      w="100%"
      h="100%"
      backgroundColor="rgba(255, 255, 255, 0.5)"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="1"
    >
      <Center h="100vh">
        <Spinner position="fixed" size="xl" />
      </Center>
    </Box>
  )
})
