import { Link } from "@chakra-ui/react"
import React from "react"
import { CloseIcon } from "./icons/close-icon"

export const RemoveFromFavouritesButton = (props) => {
  return (
    <Link onClick={props.onClick}>
      <CloseIcon color="primary" />
    </Link>
  )
}
