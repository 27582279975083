import { flow, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { createInfiniteScrollResourceable } from "../compositions/infinite-scroll-resourceable"
import { createSearchable } from "../compositions/searchable"
import { withRootStore } from "../lib"
import { withEnvironment } from "../lib/with-environment"
import { PhysicianModel } from "../models/physician"

export const PhysicianStoreModel = types.compose(
  // compose in resourceable with infinite scroll
  createInfiniteScrollResourceable({
    collection: {
      name: "physicians",
      apiGetIndexFnName: "getPhysicians",
    },
    member: {
      selectedResourceName: "selectedPhysician",
      apiGetMemberFnName: "getPhysician",
    },
  }),
  // compose in searchable functionality
  createSearchable(PhysicianModel, "physicians", "searchPhysician"),
  types
    .model("PhysicianStoreModel")
    .props({
      physicians: types.map(PhysicianModel),
      selectedPhysician: types.maybeNull(types.reference(PhysicianModel)),
      loading: types.optional(types.boolean, false),
    })
    .extend(withEnvironment())
    .extend(withRootStore())
    .actions((self) => ({
      accountUpdate: flow(function* (values) {
        self.loading = true
        const filteredValues = R.pipe(
          R.reject(R.isNil),
          R.reject(R.isEmpty)
        )(values)
        //filter nil and empty values before passing to values
        const response: any = yield self.environment.api.accountUpdate(
          filteredValues
        )
        if (response.ok) {
          const { data } = response.data
          self.rootStore.userStore.setProfile(data)
          self.loading = false
          return true
        } else {
          self.loading = false
          return false
        }
      }),
    }))
    .actions((self) => ({
      load: flow(function* () {
        yield self.getResources()
        // do other setup here?
      }),
    }))
)
export interface IPhysicianStore extends Instance<typeof PhysicianStoreModel> {}
