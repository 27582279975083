import { flow, IStateTreeNode, types } from "mobx-state-tree"
import { withEnvironment } from "../lib/with-environment"
import {
  ClinicStoreModel,
  ContactStoreModel,
  FacilityStoreModel,
  FavoriteStoreModel,
  FormOptionsStoreModel,
  IFormOptionsStore,
  IPhysicianStore,
  ISessionStore,
  IUIStore,
  IUserStore,
  SessionStoreModel,
  UIStoreModel,
  UserStoreModel,
} from "./"
import { IFacilityStore } from "./facility-store"
import { IFavoriteStore } from "./favorite-store"
import { PhysicianStoreModel } from "./physician-store"

export const RootStoreModel = types
  .model("RootStoreModel")
  .props({
    initialLoadInProgress: types.optional(types.boolean, true),
    uiStore: types.optional(UIStoreModel, {}),
    sessionStore: types.optional(SessionStoreModel, {}),
    physicianStore: types.optional(PhysicianStoreModel, {}),
    formOptionsStore: types.optional(FormOptionsStoreModel, {}),
    userStore: types.optional(UserStoreModel, {}),
    clinicStore: types.optional(ClinicStoreModel, {}),
    favoriteStore: types.optional(FavoriteStoreModel, {}),
    facilityStore: types.optional(FacilityStoreModel, {}),
    contactStore: types.optional(ContactStoreModel, {}),
  })
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    loggedInStartup: flow(function* () {
      yield self.physicianStore.load()
      yield self.clinicStore.load()
      yield self.userStore.load()
      yield self.favoriteStore.load()
    }),
  }))
  .actions((self) => ({
    startup: flow(function* () {
      yield self.sessionStore.validateToken() //load token if its there
      self.initialLoadInProgress = false

      yield self.facilityStore.load() //loads associated directory_sections and contacts as well

      if (self.sessionStore.loggedIn) {
        // logged in now do stuff we want
        yield self.loggedInStartup()
      } else {
        // logged out
      }
    }),
  }))
  .actions((self) => ({
    afterCreate() {
      self.startup()
    },
  }))

export interface IRootStore extends IStateTreeNode {
  uiStore: IUIStore
  sessionStore: ISessionStore
  physicianStore: IPhysicianStore
  formOptionsStore: IFormOptionsStore
  userStore: IUserStore
  favoriteStore: IFavoriteStore
  facilityStore: IFacilityStore
}
