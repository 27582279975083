import * as R from "ramda"
import { IUIStore } from "../../stores"
import { API_ERROR_TYPES } from "../../utils"
import { Api } from "./api"

/**
  Watches for "flash" messages from the server and reports them.
 */
export const addFlashMessageMonitor = (api: Api, uiStore: IUIStore) => {
  api.addMonitor((response) => {
    // assuming these are just CLIENT_ERROR - other types handled below in addApiErrorMonitor
    let type, title, message
    if (
      response.config.url.includes("login") &&
      R.path(["data", "error"], response)
    ) {
      //only use this for devise-jwt since we don't want to customize the serializer there for now...
      title = ""
      type = "error"
      message = R.path(["data", "error"], response)
    } else {
      const messageConfig: { [key: string]: any } = R.path(
        ["data", "meta", "message"],
        response
      )
      if (R.isNil(messageConfig) || R.isEmpty(messageConfig)) return
      ;({ title, message, type } = messageConfig)
    }
    uiStore.flashMessage.show(type, title, message)
  })
}

export const addApiErrorMonitor = (api: Api, uiStore: IUIStore) => {
  api.addMonitor((response) => {
    if (response.problem) {
      const err = API_ERROR_TYPES[response.problem]
      if (err) {
        uiStore.flashMessage.show(err.type, response.problem, err.message)
      }
    }
  })
}
