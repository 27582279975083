import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Text,
} from "@chakra-ui/react"
import styled from "@emotion/styled"
import * as R from "ramda"
import React from "react"
import { usePlacesWidget } from "react-google-autocomplete"
import { useTranslation } from "react-i18next"
import { addressDataFromGooglePlacesObject } from "../../../../utils/utils"
import { FaxIcon, OfficePhoneIcon, SearchIcon } from "../../../shared/icons"
import { InputLabel } from "./input-label"

export const Office = (props) => {
  // this APIKey is locked down on the googlemaps console
  const GOOGLE_MAPS_API_KEY = document
    .querySelector("meta[name='google-maps-api-key']")
    .getAttribute("content")

  const { t } = useTranslation()
  const { field, objectIndex, register, remove } = props

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
      componentRestrictions: { country: ["ca"] },
      fields: ["address_components", "geometry", "name"],
    },
    onPlaceSelected: (place) => {
      const { setValue, objectIndex } = props
      const data = addressDataFromGooglePlacesObject(place)
      R.forEach(
        (value) => {
          setValue(`${objectIndex}.${value[0]}`, value[1])
        },
        [
          ["ownerAttributesName", data.name],
          ["ownerAttributesAddressAttributesStreetAddress", data.streetAddress],
          ["ownerAttributesAddressAttributesCity", data.city],
          ["ownerAttributesAddressAttributesProvince", data.province],
          ["ownerAttributesAddressAttributesPostalCode", data.postalCode],
        ]
      )
    },
  })

  const checkKeyDown = (e) => {
    //the enter key should not auto submit the form
    if (e.code === "Enter") e.preventDefault()
  }

  //TODO -> ADD GOOGLE ON THE NAME SECTION AND ON SUCCESS UPDATE STREET ADDRESS FIELDS
  return (
    <Box
      bg="gray.100"
      mt={3}
      p={5}
      backgroundColor="background"
      borderRadius={4}
    >
      <StyledFieldset disabled={field._destroy == "1"}>
        <input type="hidden" {...props.register(`${props.objectIndex}.id`)} />
        <input
          type="hidden"
          {...props.register(`${props.objectIndex}.ownerAttributesId`)}
        />
        <input
          type="hidden"
          {...props.register(
            `${props.objectIndex}.ownerAttributesAddressAttributesId`
          )}
        />
        <input
          type="hidden"
          {...props.register(
            `${props.objectIndex}.ownerAttributesOfficePhoneNumberAttributesId`
          )}
        />
        <input
          type="hidden"
          {...props.register(
            `${props.objectIndex}.ownerAttributesFaxPhoneNumberAttributesId`
          )}
        />
        <FormControl className="office-google-autocomplete" mb={2}>
          <InputLabel>{t("physician.googleAutocomplete")}</InputLabel>
          <InputGroup>
            <InputLeftElement children={<SearchIcon color="text" />} />
            <Input
              backgroundColor="white"
              color="text"
              fontSize="14px"
              type="text"
              ref={ref}
              onKeyDown={(e) => checkKeyDown(e)}
            />
          </InputGroup>
        </FormControl>
        <FormControl className="office-name" mb={2}>
          <InputLabel>{t("physician.officeName")}</InputLabel>

          <InputGroup>
            <Input
              backgroundColor="white"
              color="text"
              fontSize="14px"
              type="text"
              {...register(`${objectIndex}.ownerAttributesName`, {
                required: "This is a required field",
              })}
            />
          </InputGroup>
          {props.errors?.physicianOfficesLocationsAttributes &&
            props.errors?.physicianOfficesLocationsAttributes[
              `${props.fieldIndex}`
            ]?.ownerAttributesName?.message && (
              <Text color="red" fontSize="14px">
                {
                  props.errors?.physicianOfficesLocationsAttributes[
                    `${props.fieldIndex}`
                  ]?.ownerAttributesName?.message
                }
              </Text>
            )}
        </FormControl>

        <FormControl className="street-address" mb={2}>
          <InputLabel>{t("physician.officeStreetAddress")}</InputLabel>
          <Input
            backgroundColor="white"
            color="text"
            fontSize="14px"
            type="text"
            {...props.register(
              `${objectIndex}.ownerAttributesAddressAttributesStreetAddress`,
              {
                required: "This field is required",
              }
            )}
          />
        </FormControl>
        {props.errors?.physicianOfficesLocationsAttributes &&
          props.errors?.physicianOfficesLocationsAttributes[
            `${props.fieldIndex}`
          ]?.ownerAttributesAddressAttributesStreetAddress?.message && (
            <Text color="red" fontSize="14px">
              {
                props.errors?.physicianOfficesLocationsAttributes[
                  `${props.fieldIndex}`
                ]?.ownerAttributesAddressAttributesStreetAddress?.message
              }
            </Text>
          )}

        <FormControl className="city" mb={2}>
          <InputLabel>{t("physician.officeCity")}</InputLabel>
          <Input
            backgroundColor="white"
            color="text"
            fontSize="14px"
            type="text"
            {...register(
              `${objectIndex}.ownerAttributesAddressAttributesCity`,
              {
                required: "This field is required",
              }
            )}
          />
        </FormControl>
        {props.errors?.physicianOfficesLocationsAttributes &&
          props.errors?.physicianOfficesLocationsAttributes[
            `${props.fieldIndex}`
          ]?.ownerAttributesAddressAttributesCity?.message && (
            <Text color="red" fontSize="14px">
              {
                props.errors?.physicianOfficesLocationsAttributes[
                  `${props.fieldIndex}`
                ]?.ownerAttributesAddressAttributesCity?.message
              }
            </Text>
          )}
        <FormControl className="province" mb={2}>
          <InputLabel>{t("physician.officeProvince")}</InputLabel>
          <Input
            backgroundColor="white"
            color="text"
            fontSize="14px"
            type="text"
            {...props.register(
              `${props.objectIndex}.ownerAttributesAddressAttributesProvince`,
              {
                required: "This field is required",
              }
            )}
          />
        </FormControl>
        {props.errors?.physicianOfficesLocationsAttributes &&
          props.errors?.physicianOfficesLocationsAttributes[
            `${props.fieldIndex}`
          ]?.ownerAttributesAddressAttributesProvince?.message && (
            <Text color="red" fontSize="14px">
              {
                props.errors?.physicianOfficesLocationsAttributes[
                  `${props.fieldIndex}`
                ]?.ownerAttributesAddressAttributesProvince?.message
              }
            </Text>
          )}

        <FormControl className="postal" mb={2}>
          <InputLabel>{t("physician.officePostal")}</InputLabel>
          <Input
            backgroundColor="white"
            color="text"
            fontSize="14px"
            type="text"
            {...register(
              `${objectIndex}.ownerAttributesAddressAttributesPostalCode`
            )}
          />
        </FormControl>

        <FormControl className="office-phone" mb={2}>
          <InputLabel>{t("physician.officePhone")}</InputLabel>
          <InputGroup>
            <InputLeftElement children={<OfficePhoneIcon color="text" />} />
            <Input
              backgroundColor="white"
              color="text"
              fontSize="14px"
              type="text"
              {...register(
                `${objectIndex}.ownerAttributesOfficePhoneNumberAttributesPhoneNumber`
              )}
            />
          </InputGroup>
        </FormControl>

        <FormControl className="office-fax" mb={2}>
          <InputLabel>{t("physician.officeFax")}</InputLabel>
          <InputGroup>
            <InputLeftElement children={<FaxIcon color="text" />} />
            <Input
              backgroundColor="white"
              color="text"
              fontSize="14px"
              type="text"
              {...register(
                `${objectIndex}.ownerAttributesFaxPhoneNumberAttributesPhoneNumber`
              )}
            />
          </InputGroup>
        </FormControl>
      </StyledFieldset>
      <Flex>
        {typeof field.id == "string" && field.id.startsWith("new-") ? (
          <Button size="xs" onClick={remove}>
            x {t("physician.removeButton")}
          </Button>
        ) : (
          <FormControl mb={2}>
            <InputLabel>{t("physician.removeSavedRecord")}</InputLabel>
            <Switch {...register(`${objectIndex}._destroy`)} value={"1"} />
          </FormControl>
        )}
      </Flex>
    </Box>
  )
}

const StyledFieldset = styled.fieldset`
  ${(props) => (props.disabled ? "border: 2px solid red" : "border: 0px")}
`
