import { Icon } from "@chakra-ui/react"
import React from "react"

export const UserIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.00002 2.66669C9.47335 2.66669 10.6667 3.86002 10.6667 5.33335C10.6667 6.80669 9.47335 8.00002 8.00002 8.00002C6.52669 8.00002 5.33335 6.80669 5.33335 5.33335C5.33335 3.86002 6.52669 2.66669 8.00002 2.66669ZM8.00002 13.3334C8.00002 13.3334 13.3334 13.3334 13.3334 12C13.3334 10.4 10.7334 8.66669 8.00002 8.66669C5.26669 8.66669 2.66669 10.4 2.66669 12C2.66669 13.3334 8.00002 13.3334 8.00002 13.3334Z"
      fill="currentColor"
    />
  </Icon>
)
