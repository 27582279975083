import { Box, Center, Flex, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import * as R from "ramda"
import React from "react"
import { useMst } from "../../../../setup"
import { AddToFavouritesButton } from "../../../shared/add-to-favourites-btn"
import { PhysicianAvatar } from "../shared"

const SpecialtyText = styled(Text)``
SpecialtyText.defaultProps = {
  color: "primary",
  fontWeight: "700",
  fontSize: "16px",
}

export const Header = (props) => {
  const { favoriteStore } = useMst()

  const handleClick = () => {
    favoriteStore.createFavorite("Physician", props.physicianId)
  }

  const renderSpecialties = () => {
    return R.map(
      (specialty) => (
        <SpecialtyText key={`p-specialty-${specialty.id}`}>
          {specialty.name}
        </SpecialtyText>
      ),
      props.specialties
    )
  }

  return (
    <Flex bg="background" ml="-16px" mr="-16px" minH="160px" p="32px">
      <Center pr="16px">
        <PhysicianAvatar
          name={props.name}
          profileImage={props.profileImage}
          size="xl"
        />
      </Center>

      <Center>
        <Box>
          <Text color="text" fontWeight="700" fontSize="24px">
            {props.name}
          </Text>
          <Box mt="8px" mb="8px">
            {renderSpecialties()}
          </Box>
          {!props.isFavorited && (
            <AddToFavouritesButton onClick={handleClick} />
          )}
        </Box>
      </Center>
    </Flex>
  )
}
