import { Box, Center, Spinner } from "@chakra-ui/react"
import React from "react"

export const LoadingSpinner = ({ visible }: { visible: boolean }) => {
  return (
    <Box
      visibility={visible ? "visible" : "hidden"}
      position="fixed"
      w="100%"
      h="100%"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="1"
    >
      <Center h="100vh">
        <Spinner position="fixed" size="xl" />
      </Center>
    </Box>
  )
}
