import { Box, Container, Link, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React from "react"
import { useTranslation } from "react-i18next"
import { CONSTANTS } from "../../services/constants"

const TextBody = styled(Text)``
TextBody.defaultProps = {
  color: "text",
  fontWeight: "400",
  fontSize: "14px",
  mb: "8px",
}

const SubHeaderText = styled(Text)``
SubHeaderText.defaultProps = {
  color: "text",
  fontWeight: "400",
  fontSize: "24px",
}

const SubHeader = ({ title }) => {
  return (
    <Box mt="24px" mb="16px">
      <SubHeaderText>{title}</SubHeaderText>
    </Box>
  )
}

export const AboutScreen = () => {
  const { t } = useTranslation()
  return (
    <Container mb="32px">
      <SubHeader title="About"></SubHeader>
      <Text fontSize="16px" fontWeight="700" mb="8px" color="primary">
        {t("about.slogan")}
      </Text>
      <TextBody>{t("about.welcomeMessage")}</TextBody>

      <Box ml="8px">
        <TextBody> • {t("about.connectBullet")}</TextBody>
        <TextBody> • {t("about.contactBullet")}</TextBody>
        <TextBody>• {t("about.getToKnowBullet")} </TextBody>
      </Box>

      <TextBody>
        Using this directory is voluntary. Enter and manage your own information
        as you wish, and use the device of your choice. We will never share your
        information with outside parties.{" "}
        <Link href={CONSTANTS.TERMS_AND_CONDITIONS_LINK} isExternal>
          Read more here.
        </Link>
      </TextBody>
      <TextBody>
        Note: This directory is <Text as="strong">not</Text> a secure messaging
        program to be used for the confidential exchange of patient information.
        Please follow the{" "}
        <Link href={CONSTANTS.EMAIL_AND_TEXT_POLICIES_LINK} isExternal>
          PHC E-mail Policy and Texting Policy
        </Link>{" "}
        when connecting with colleagues using this directory.
      </TextBody>
      <Text fontSize="14px" fontWeight="700" mt="8px">
        <Link href={CONSTANTS.NEED_HELP_LINK} isExternal>
          {t("about.help")}
        </Link>
      </Text>
      <TextBody>
        For assistance, or if you lose your device, or want to delete your
        information if you leave PHC, contact{" "}
        <Link href="mailto:pass@providencehealth.bc.ca">
          pass@providencehealth.bc.ca
        </Link>
      </TextBody>
      <Text fontSize="14px" color="text" fontWeight="400" as="i">
        {t("about.initiative")}{" "}
      </Text>
    </Container>
  )
}
