import { Box } from "@chakra-ui/react"
import React from "react"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ConnectForm, PhoneNumber } from "./index"

export const PhoneNumbers = () => {
  const { t } = useTranslation()
  return (
    <ConnectForm>
      {({ register, setValue, watch, control, formState: { errors } }) => {
        const { fields } = useFieldArray({
          control,
          name: "phoneNumbersAttributes",
        })

        const watchFieldArray = watch("phoneNumbersAttributes")
        const controlledFields = fields.map((field, index) => {
          return {
            ...field,
            ...watchFieldArray[index],
          }
        })

        const handlePreferredChange = (preferredType) => {
          fields.map((field, index) => {
            setValue(
              `phoneNumbersAttributes[${index}].preferred`,
              field.phoneType == preferredType
            )
          })
        }
        const getPreferredPhoneType = controlledFields.find((f) => f?.preferred)
          ?.phoneType

        return (
          <Box mt={5}>
            {controlledFields.map((field, index) => (
              <PhoneNumber
                register={register}
                errors={errors}
                field={field}
                fieldName={"phoneNumbersAttributes"}
                fieldIndex={index}
                key={field.id}
                handlePreferredChange={handlePreferredChange}
              />
            ))}
          </Box>
        )
      }}
    </ConnectForm>
  )
}
