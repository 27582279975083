import { Icon } from "@chakra-ui/react"
import React from "react"

export const OfficePhoneIcon = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M11.837 8.58199C11.7687 8.51353 11.6875 8.45921 11.5982 8.42215C11.5088 8.38509 11.413 8.36602 11.3163 8.36602C11.2195 8.36602 11.1237 8.38509 11.0344 8.42215C10.945 8.45921 10.8638 8.51353 10.7955 8.58199L9.62135 9.75613C9.077 9.59408 8.06122 9.22578 7.41743 8.58199C6.77364 7.9382 6.40534 6.92243 6.24329 6.37808L7.41743 5.20393C7.4859 5.13559 7.54021 5.05441 7.57727 4.96505C7.61433 4.87569 7.63341 4.7799 7.63341 4.68316C7.63341 4.58642 7.61433 4.49063 7.57727 4.40126C7.54021 4.3119 7.4859 4.23073 7.41743 4.16238L4.47103 1.21597C4.40268 1.14751 4.3215 1.09319 4.23214 1.05613C4.14278 1.01908 4.04699 1 3.95025 1C3.85351 1 3.75772 1.01908 3.66836 1.05613C3.579 1.09319 3.49782 1.14751 3.42947 1.21597L1.43181 3.21364C1.1519 3.49355 0.994267 3.87805 1.00016 4.27066C1.0171 5.31958 1.2948 8.96281 4.16607 11.8341C7.03735 14.7054 10.6806 14.9823 11.7302 15H11.7509C12.1398 15 12.5074 14.8468 12.7858 14.5684L14.7835 12.5707C14.8519 12.5023 14.9062 12.4212 14.9433 12.3318C14.9803 12.2424 14.9994 12.1467 14.9994 12.0499C14.9994 11.9532 14.9803 11.8574 14.9433 11.768C14.9062 11.6787 14.8519 11.5975 14.7835 11.5291L11.837 8.58199ZM11.7435 13.5261C10.8242 13.5106 7.67893 13.2638 5.20763 10.7918C2.72823 8.31239 2.48809 5.15606 2.47336 4.25519L3.95025 2.7783L5.8551 4.68316L4.90268 5.63558C4.8161 5.7221 4.75244 5.82882 4.71746 5.94611C4.68248 6.0634 4.67727 6.18756 4.70232 6.30736C4.72 6.39207 5.15238 8.40079 6.37514 9.62354C7.5979 10.8463 9.60661 11.2787 9.69132 11.2964C9.81105 11.3221 9.93534 11.3173 10.0527 11.2824C10.1701 11.2476 10.2769 11.1837 10.3631 11.0967L11.3163 10.1443L13.2211 12.0492L11.7435 13.5261Z"
    />
  </Icon>
)
